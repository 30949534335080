import { TextField } from '@mui/material';

export default [
    {
        label: 'Contains',
        value: 'contains',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
                return null;
            }
            return (params) => params.value?.toString().toLowerCase().includes(filterItem.value.toLowerCase());
        },
        InputComponent: (props) => (
            <TextField
                variant="outlined"
                size="small"
                value={props.item.value || ''}
                onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                placeholder="Search by name"
                style={{ width: '100%' }}
            />
        )
    }
];