import React from 'react'
import moment from 'moment';
import { useGetOne } from 'react-admin';

export const NewRegistrationSale = ({ sale, days }) => {

    const { data, isLoading, error } = useGetOne(
        'users',
        { id: sale.user.id }
    );

    if (isLoading) return <span>Loading...</span>
    if (error) return <span>Error</span>

    let isNewRegistration = false;

    // last 7 days - user.createDateTime
    const userCreatedDate = moment(data.createDateTime, 'x');
    const now = moment();
    const diff = now.diff(userCreatedDate, 'days');

    if (diff <= (days ? days : 7)) isNewRegistration = true;

    return (
        isNewRegistration ? (
            <span style={{ color: 'green' }}>Yes</span>
        ) : <span>No</span>
    )

}