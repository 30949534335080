import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { useSearchParams } from 'react-router-dom'

// UI
import { Grid, Typography } from '@material-ui/core';

// Libs
import moment from "moment";

// Firebase
import { db } from "../database/firebase";
import { collection, getDoc, getDocs, query, orderBy, where, limit, updateDoc, doc, getCountFromServer } from "firebase/firestore";

// DataGrid
import { DataGridPremium, GridToolbarContainer, trTR } from '@mui/x-data-grid-premium';

// Definitions

const SEATED_COLUMNS = [
    { field: 'id', headerName: 'Product ID', width: 150, filterable: false, valueGetter: (params) => params.row.id },
    { field: 'name', headerName: 'Product Name', width: 200, filterable: false, valueGetter: (params) => params.row.name },
    { field: 'count', headerName: 'Sold', width: 100, filterable: false, valueGetter: (params) => params.row.count ? params.row.count : 0 },
    { field: 'total', headerName: 'Total', width: 100, filterable: false, valueGetter: (params) => params.row.total, valueFormatter: (params) => params.value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) },
    { field: 'remaining', headerName: 'Remaining', width: 100, filterable: false, valueGetter: (params) => params.value ? params.row.remaining : 0, valueFormatter: (params) => params.value ? params.value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : 0 }
];
const D_COLUMNS = [
    {
        field: 'title', headerName: 'Title', width: 200, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.category ? params.row.category.toUpperCase() : ""}</b>;
        }
    },
    {
        field: 'category', headerName: 'Category', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.category ? params.row.category.toUpperCase() : "";
        }
    },
    {
        field: 'price', headerName: 'Price', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.price ? params.row.price.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";
        }
    },
    {
        field: 'original-stock', headerName: 'Total Stock', width: 100, filterable: false, valueGetter: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.stock;
        }
    },

    {
        field: 'sold', headerName: 'Sold', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.sold}</b>;
            return params.row.sold ? params.row.sold : 0;
        }
    },
    {
        field: 'stock', headerName: 'Remaining', width: 100, filterable: false,
        renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.stock - (params.row.sold ? params.row.sold : 0);
        }
    },
    {
        field: 'totalSale', headerName: 'Total Sale', width: 100, filterable: false, valueGetter: (params) => {

            let totalSale = params.row.price * params.row.sold;
            if (params.row.id === 'total') totalSale = params.row.totalSale;

            return totalSale ? totalSale.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";
        }, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.value}</b>;
            return params.value;
        }
    }
];
const R_COLUMNS = [
    {
        field: 'title', headerName: 'Title', width: 200, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.category ? params.row.category.toUpperCase() : ""}</b>;
        }
    },
    {
        field: 'category', headerName: 'Category', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.category ? params.row.category.toUpperCase() : "";
        }
    },
    {
        field: 'price', headerName: 'Remaining', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            if (params.row.sold && params.row.sold != 0 && params.row.price && params.row.downPaymentAmount) {
                let remainingAmount = params.row.price - params.row.downPaymentAmount;
                return remainingAmount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
            } else {
                return "-";
            }
        }
    },
    {
        field: 'original-stock', headerName: 'Total Stock', width: 100, filterable: false, valueGetter: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.stock;
        }
    },

    {
        field: 'sold', headerName: 'Sold', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.sold}</b>;
            return params.row.sold ? params.row.sold : 0;
        }
    },
    { field: 'stock', headerName: 'Remaining Stock', width: 100, filterable: false },
    {
        field: 'totalRemaining', headerName: 'Total Remaining', width: 100, filterable: false, valueGetter: (params) => {

            let remainingAmount = params.row.price - params.row.downPaymentAmount;
            let totalRmaining = remainingAmount * params.row.sold;
            if (params.row.id === 'total') totalRmaining = params.row.totalRmainingAmount;

            return totalRmaining ? totalRmaining.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";

        }, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.value}</b>;
            return params.value;
        }
    }
];
const C_COLUMNS = [
    {
        field: 'code', headerName: 'Coupon Code', width: 200, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return <b>{params.row.code.toUpperCase()}</b>;
            return params.row.code.toUpperCase();
        }
    },
    {
        field: 'discount', headerName: 'Discount (TL)', width: 100, filterable: false, renderCell: (params) => {
            if (params.row.id === 'total') return null;
            return params.row.discount ? params.row.discount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }) : "-";
        }
    },
    { field: 'stock', headerName: 'Stock', width: 100, filterable: false },
    {
        field: 'used', headerName: 'Used', width: 100, filterable: false, renderCell: (p) => {
            if (p.row.id === 'total') return <b>{p.row.totalUsed}</b>;
            return p.row.used ? p.row.used : 0;

        }
    },
    {
        field: 'remaining', headerName: 'Remaining', width: 100, filterable: false, renderCell: (p) => {
            if (p.row.id === 'total') return null;
            let remaining = p.row.stock - (p.row.used ? p.row.used : 0)
            if (remaining < 0) remaining = 0;
            return remaining;
        }
    },
    {
        field: 'totalDiscount', headerName: 'Total Discount (TL)', width: 150, filterable: false, renderCell: (p) => {
            if (p.row.id === 'total') return p.row.totalDiscount ? <b>{p.row.totalDiscount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b> : "-";
            let totalDisc = p.row.discount * (p.row.used ? p.row.used : 0);
            if (totalDisc == 0) return "-";
            else return totalDisc.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
        }
    }
];

let productCategories = [
    'deal', 'general', 'promotion', 'vip', 'invitation', 'extras', 'guestlist', 'reservation'
];


const ORGANISATION_OR_VENUE_SALE_CHANNELS = ["vm", "vp", "ocm", "op"];

const SaleReportPage = () => {

    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [eventData, setEventData] = useState(false);
    const [isSeatedEvent, setIsSeatedEvent] = useState(false);
    const [stockData, setStockData] = useState(false);

    const [salesData, setSalesData] = useState([]);
    const [salesDataGrid, setSalesDataGrid] = useState([]);
    const [columns, setColumns] = useState(D_COLUMNS);

    const [couponsData, setCouponsData] = useState(false);
    const [couponColumns, setCouponColumns] = useState(C_COLUMNS);

    const [remainingData, setRemainingData] = useState([]);
    const [remainingColumns, setRemainingColumns] = useState(R_COLUMNS);

    const eventID = searchParams.get('eventID') ? searchParams.get('eventID') : '1';
    const stockID = searchParams.get('stockID') ? searchParams.get('stockID') : null;

    useEffect(() => {
        const fetchData = async () => {

            // fetch event data
            const eventDoc = doc(db, "events-v2", eventID);
            const eventSnap = await getDoc(eventDoc);
            const event = eventSnap.data();

            if (event && event.type === 3) setIsSeatedEvent(true);
            setEventData(event);

            // fetch stock
            const stockDoc = doc(db, "stocks", stockID ? stockID : event.sessions[0].times[0].id);
            const stockSnap = await getDoc(stockDoc);
            const stock = stockSnap.data();
            setStockData(stock);

            // compute stats to show in tables
            let sales = await computeSalesData(event, stock);
            setSalesData(sales);

            if (event.coupons) await computeCouponsData(event, sales);
            await computeRemainingPayments(stock, sales);

            setIsLoading(false);
        };

        try {
            fetchData();
        } catch (error) {
            console.error(error);
            setError(error.message);
        }

    }, []);

    if (!eventID) return <EmptyState title="Error" message="Event ID is missing" isError={true} />


    const computeSalesData = async (event, stock) => {

        if (!event || !event.id) return;

        const q = query(
            collection(db, "sales"),
            where("event.id", "==", event.id)
        );

        const querySnapshot = await getDocs(q);

        let salesData = [];

        querySnapshot.forEach((doc) => {
            let sale = doc.data();

            if (sale.status === 1) {

                if (sale.details) {

                    Object.keys(sale.details).forEach((productCategory) => {
                        if (Object.values(sale.details[productCategory])) {
                            Object.values(sale.details[productCategory]).forEach((product) => {

                                let isOrganisationOrVenueSale = ORGANISATION_OR_VENUE_SALE_CHANNELS.includes(sale.chanel);

                                let productKey = product.label.key;
                                let stockProduct = stock.stock[productCategory].find(r => r.id === productKey);

                                if (salesData[productKey]) {
                                    salesData[productKey].sold = salesData[productKey].sold + product.count;

                                    let isCommissionExtra = event.isCommissionExtra ? event.isCommissionExtra : false;
                                    isCommissionExtra = isOrganisationOrVenueSale ? false : isCommissionExtra;
                                    let computedPrice = (
                                        (stockProduct.price * product.count) +
                                        (isCommissionExtra ? (stockProduct.price * event.commissionFee / 100) : 0)
                                    );
                                    salesData[productKey].totalSale = salesData[productKey].totalSale + computedPrice;
                                } else {
                                    salesData[productKey] = {
                                        sold: product.count,
                                        totalSale: (
                                            (stockProduct.price * product.count) +
                                            (
                                                isOrganisationOrVenueSale ? 0 :
                                                    (event.isCommissionExtra ? (stockProduct.price * event.commissionFee / 100) : 0)
                                            )
                                        ),
                                        price: stockProduct.price + (event.isCommissionExtra ? (stockProduct.price * event.commissionFee / 100) : 0),
                                        stock: stockProduct.stock,
                                        title: product.label.title.en,
                                        category: productCategory,
                                        hasDownPayment: stockProduct.hasDownPayment ? true : false,
                                        downPaymentAmount: stockProduct.downPaymentAmount ? stockProduct.downPaymentAmount : 0
                                    };
                                }
                            });
                        }
                    });
                }
            }
        });

        let salesDataGrid = [];

        Object.keys(salesData).map(productID => {
            salesDataGrid.push({
                id: productID,
                title: salesData[productID].title,
                category: salesData[productID].category,
                price: salesData[productID].hasDownPayment ? salesData[productID].downPaymentAmount : salesData[productID].price,
                stock: salesData[productID].stock,
                sold: salesData[productID].sold,
                totalSale: salesData[productID].totalSale,
                remaining: salesData[productID].stock - salesData[productID].sold
            });
        });


        // TODO: scan stock and add the products without sale into salesDataGrid


        // We need a total row at the end, only for sold and total sale
        // We need a total row at the end, only for sold and total sale
        let totalSold = 0;
        let totalSale = 0;
        salesDataGrid.forEach((product) => {
            totalSold += product.sold;
            totalSale += product.sold * product.price;
        });

        let totalRow = {
            id: 'total',
            category: 'Total',
            title: '',
            price: '',
            stock: '',
            sold: totalSold,
            totalSale: totalSale
        }

        salesDataGrid.push(totalRow);

        setSalesDataGrid(salesDataGrid);

        return querySnapshot.docs;
    }

    const computeCouponsData = async (event, sales) => {

        if (!event || !event.id) return;

        let couponsData = [];

        if (event.coupons) {

            event.coupons.forEach((coupon) => {

                let couponData = {
                    id: coupon.code.toLowerCase().concat(Math.random().toString(36).substring(7)),
                    code: coupon.code,
                    discount: coupon.discount,
                    stock: coupon.stock,
                    used: coupon.used
                }
                couponsData.push(couponData);
            });


            // We need a total row at the end, only for sold and total sale
            let totalDiscount = 0;
            let totalUsed = 0;
            couponsData.forEach((coupon) => {
                if (coupon.used) {
                    totalDiscount += (coupon.discount * coupon.used);
                    totalUsed += coupon.used ? coupon.used : 0;
                }
            });

            let totalRow = {
                id: 'total', code: 'Total', stock: '', used: '', remaining: '', discount: '', totalDiscount: totalDiscount, totalUsed: totalUsed
            }

            couponsData.push(totalRow);

            setCouponsData(couponsData);
        }
    }

    const computeRemainingPayments = async (stockData, sales) => {

        let remainingPayments = [];

        sales.forEach((saleDoc) => {
            let sale = saleDoc.data();
            if (sale.details) {
                Object.keys(sale.details).forEach((productCategory) => {
                    if (Object.values(sale.details[productCategory])) {
                        Object.values(sale.details[productCategory]).forEach((product) => {

                            let productKey = product.label.key;
                            let stockProduct = stockData.stock[productCategory].find(r => r.id === productKey);

                            if (stockProduct.hasDownPayment) {
                                let productData = {
                                    id: productKey,
                                    category: productCategory,
                                    title: product.label.title.en,
                                    price: stockProduct.price,
                                    stock: stockProduct.stock,
                                    sold: product.count,
                                    downPaymentAmount: stockProduct.downPaymentAmount
                                }

                                remainingPayments.push(productData);
                            }
                        });
                    }
                });
            }
        });

        // We need a total row at the end, only for sold and total sale
        let totalSold = 0;
        let totalRmainingAmount = 0;
        remainingPayments.forEach((product) => {
            totalSold += product.sold;
            let remainingAmount = product.price - product.downPaymentAmount;
            totalRmainingAmount += product.sold * remainingAmount;
        });

        let totalRow = {
            id: 'total', category: 'Total', title: '', price: '', stock: '',
            sold: totalSold, totalRmainingAmount: totalRmainingAmount
        }

        remainingPayments.push(totalRow);
        setRemainingData(remainingPayments);

        // setTotalRmainingAmountSummary(totalRmainingAmount);
    }

    if (error) return <EmptyState title="Error" message={error} isError={true} />
    if (isLoading || !eventData) return <EmptyState title="Loading" message="Please wait..." isError={false} />

    if (isSeatedEvent) return (
        (eventData && eventData.sessions) && (
            <div style={{ marginBottom: 50 }}>

                <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                    <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 10 }}>
                        <Typography variant="h4" gutterBottom>
                            Sale Report / {eventData.name}
                        </Typography>
                        <Typography variant="h4" gutterBottom style={{ fontSize: 24, paddingTop: 10, paddingBottom: 10 }}>
                            {moment(eventData.startdate.toDate()).format("LL")} {stockData.time ? stockData.time : ""}
                        </Typography>
                    </Grid>
                </Grid>
                <SessionTimeAndAvailability event={eventData} stock={stockData} />
            </div>
        )
    )


    // summary calculations
    let totalProductSalesSummary = salesDataGrid.reduce((a, b) => a + b.id !== 'total' ? b.totalSale : 0, 0);
    let totalCouponDiscountSummary = couponsData.reduce((a, b) => a + b.id !== 'total' ? b.totalDiscount : 0, 0);
    let totalRmainingAmountSummary = remainingData.reduce((a, b) => a + b.id !== 'total' ? b.totalRmainingAmount : 0, 0);


    let isCommissionExtra = eventData.isCommissionExtra ? eventData.isCommissionExtra : false;


    // Organisation or Venue Sales
    let totalOrganisationOrVenueSales = 0;
    salesData.map((sale, i) => {
        sale = sale.data(); if (sale.status == 1) {
            if (ORGANISATION_OR_VENUE_SALE_CHANNELS.includes(sale.channel)) {
                let saleAmount = sale.paymentTotal;

                let amountToBeAdded = 0;

                amountToBeAdded = (
                    saleAmount
                    - (eventData.servicefee ? eventData.servicefee : 0)
                );

                if (isCommissionExtra) {
                    let saleCommission = saleAmount * (1 - (100 / (100 + eventData.commissionFee)));
                    amountToBeAdded = amountToBeAdded - saleCommission;
                }

                totalOrganisationOrVenueSales += amountToBeAdded;
            }
        }
    });

    let subTotal = totalProductSalesSummary - totalOrganisationOrVenueSales - totalCouponDiscountSummary - totalRmainingAmountSummary;



    // Commission
    let totalCommissionAmount = 0;

    if (!isCommissionExtra) { // isIncluded
        totalCommissionAmount = subTotal * eventData.commissionFee / 100;
    } else {
        totalCommissionAmount = subTotal * (1 - (100 / (100 + eventData.commissionFee)));
    }

    let totalPayable = subTotal - totalCommissionAmount;

    return (
        <div style={{ flex: 1 }}>
            <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 10 }}>
                    <Typography variant="h4" gutterBottom>
                        Sale Report / {eventData.name} - {moment(eventData.startdate.toDate()).format("LL")} / {stockData.time ? stockData.time : ""}
                    </Typography>
                </Grid>
            </Grid>

            <div style={{ marginLeft: 30, marginRight: 30 }}>

                {/* Product Sales */}
                <div>
                    <h3>Sales</h3>
                    <DataGridPremium
                        rows={salesDataGrid}
                        density="compact"
                        initialState={{
                            columns: { columnVisibilityModel: {} },
                            pagination: { paginationModel: { pageSize: 25 } },
                        }}
                        onColumnVisibilityModelChange={(model) => {
                            setColumns(
                                columns.map((column) => ({
                                    ...column,
                                    hide: !model[column.field],
                                }))
                            );
                        }}
                        style={{ flex: 1, width: '100%' }}
                        getRowId={(row) => row.id}
                        columns={columns}
                        hideFooter={true}
                        slots={{
                            toolbar: CustomTableHeader
                        }}
                    />
                </div>


                <hr style={{ marginTop: 30, marginBottom: 20 }} />

                {/* COUPONS */}
                <div>
                    <h3>Coupons</h3>
                    <DataGridPremium
                        rows={couponsData}
                        density="compact"
                        initialState={{
                            columns: { columnVisibilityModel: {} },
                            pagination: { paginationModel: { pageSize: 25 } },
                        }}
                        onColumnVisibilityModelChange={(model) => {
                            setCouponColumns(
                                couponColumns.map((column) => ({
                                    ...column,
                                    hide: !model[column.field],
                                }))
                            );
                        }}
                        style={{ flex: 1, width: '100%' }}
                        getRowId={(row) => row.id}
                        columns={couponColumns}
                        hideFooter={true}
                        slots={{
                            toolbar: CustomTableHeader
                        }}
                    />
                </div>

                {/* REMAINING PAYMENTS (FOR DOWN PAYMENT ENABLED PRODUCTS) */}
                <div style={{ marginTop: 50, marginBottom: 50 }}>
                    <h3>Remaining Payments</h3>
                    <DataGridPremium
                        rows={remainingData}
                        density="compact"
                        initialState={{
                            columns: { columnVisibilityModel: {} },
                            pagination: { paginationModel: { pageSize: 25 } },
                        }}
                        onColumnVisibilityModelChange={(model) => {
                            setRemainingColumns(
                                remainingColumns.map((column) => ({
                                    ...column,
                                    hide: !model[column.field],
                                }))
                            );
                        }}
                        style={{ flex: 1, width: '100%' }}
                        getRowId={(row) => row.id}
                        columns={remainingColumns}
                        hideFooter={true}
                        slots={{
                            toolbar: CustomTableHeader
                        }}
                    />
                </div>


                {/* SUMMARY SECTION */}
                <div>

                    {/* Right Aligned */}

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 50, marginBottom: 50, marginRight: 50 }}>

                        <div>
                            <div style={{ width: 500, fontSize: 16 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Total Product Sales</div>
                                    <div>{totalProductSalesSummary.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Total Remaining Amount</div>
                                    <div>{totalRmainingAmountSummary.toLocaleString('tr-TR', { style: 'currency', 'currency': 'TRY' })}</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Total Coupon Discount</div>
                                    <div>{totalCouponDiscountSummary.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Organisator Sales (Cash)</div>
                                    <div>{totalOrganisationOrVenueSales.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Sub Total</div>
                                    <div><b>{(subTotal).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b></div>
                                </div>

                            </div>

                            <div style={{ width: 500, fontSize: 16, marginTop: 30 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Gişe Kıbrıs Fee (<b>{eventData.isCommissionExtra ? 'extra' : 'included'}</b> {eventData.commissionFee}%)</div>
                                    <div>{totalCommissionAmount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>Total Payable</div>
                                    <div>
                                        <b>{totalPayable.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>

    );

};

const CustomTableHeader = () => {
    return (
        <GridToolbarContainer>
            {/* <GridToolbarColumnsButton /> */}
            {/* <GridToolbarFilterButton /> */}
            {/* <GridToolbarDensitySelector /> */}
            {/* <GridToolbarExport csvOptions={{ disableToolbarButton: true }} printOptions={{ hideToolbar: true }} excelOptions={{ fileName: `gisekibris-sales-${moment().format('LL-LT')}.xlsx` }} /> */}
        </GridToolbarContainer>
    );
}

const EmptyState = ({ title, message, isError }) => {
    return (
        <div style={{ flex: 1 }}>
            <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 20 }}>
                    <Typography variant="h4" gutterBottom>
                        {title ? title : "Sale Report"}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        {isError ? "Error" : "Message"}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {message}
                    </Typography>
                </Grid>

            </Grid>
        </div>
    )
}


// For Seated Events
const SessionTimeAndAvailability = ({ event, stock }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {

        if (!event || !stock) return setError(true);

        const fetchData = async () => {

            const collectionRef = collection(db, 'sales');
            let q = query(collectionRef, orderBy('createdate', 'desc'),
                where('event.id', '==', event.id),
                // where('status', '==', 1) // requires index, so we will do this on the client side
            );
            const dataSnapshot = await getDocs(q);

            // loop through docs and add id
            let docs = [];

            dataSnapshot.forEach(doc => {
                let sale = doc.data();

                if (sale.event.stockID) {
                    if (sale.status === 1 && sale.event.stockID == stock.stockID) {
                        docs.push({ ...sale, id: doc.id });
                    }

                    if (sale.status === 0 && sale.isReservation) {
                        docs.push({ ...sale, id: doc.id });
                    }


                } else {
                    // if (sale.status === 1) {
                    //     docs.push({ ...sale, id: doc.id });
                    // }

                    // if (sale.status === 0 && sale.isReservation) {
                    //     docs.push({ ...sale, id: doc.id });
                    // }
                }
            });

            setData(docs);
            setLoading(false);
        };

        fetchData();

    }, [event, stock]);

    if (loading) return <div>Loading...</div>;

    if (error) return <div>Error</div>;

    let tableData = {};

    if (data) {

        Object.values(data).map(sale => {
            if (typeof sale === 'object' && sale.seats) {

                // sale.isInvitation
                // sale.isReservation

                sale.seats.forEach(seat => {

                    // console.log(sale)

                    console.log(seat.id, seat.productID, seat.selectedOption.title.en, seat.selectedOption.price, sale.seats.length, sale.paymentTotal);

                    // tableData should have sessionEpoch first which is in sale.event.session
                    // then it should have productID which is in seats[].productID

                    // if tableData doesn't have sessionEpoch, create it

                    if (!tableData[sale.event.stockID]) {
                        tableData[sale.event.stockID] = {};
                    }

                    if (sale.isReservation) {
                        // Then we can increment the count in productID
                        if (
                            !tableData[sale.event.stockID][`${seat.productID}-RES`]
                        ) {
                            // find name of the productID and add it to the tableData
                            let product = stock.stock.find(r => r.id === seat.productID);

                            // let product = {
                            //     title: { en: seat.selectedOption.title.en, }, price: seat.selectedOption.price
                            // };

                            tableData[sale.event.stockID][`${seat.productID}-RES`] = {
                                name: product.title.en + " RESERVATION",
                                count: 1,
                                total: 0,
                                remaining: product.price
                            };
                        } else {

                            let product = stock.stock.find(r => r.id === seat.productID);
                            tableData[sale.event.stockID][`${seat.productID}-RES`].count = tableData[sale.event.stockID][`${seat.productID}-RES`].count + 1;
                            tableData[sale.event.stockID][`${seat.productID}-RES`].total = tableData[sale.event.stockID][`${seat.productID}-RES`].total + 0;
                            tableData[sale.event.stockID][`${seat.productID}-RES`].remaining = tableData[sale.event.stockID][`${seat.productID}-RES`].remaining + product.price;
                        }
                    }
                    else if (sale.isInvitation) {
                        // Then we can increment the count in productID
                        if (
                            !tableData[sale.event.stockID][`${seat.productID}-INV`]
                        ) {
                            // find name of the productID and add it to the tableData
                            // let product = stock.stock.find(r => r.id === seat.productID);

                            let product = {
                                title: { en: seat.selectedOption.title.en, }, price: seat.selectedOption.price
                            };

                            tableData[sale.event.stockID][`${seat.productID}-INV`] = {
                                name: product.title.en + " INVIATION",
                                count: 1,
                                total: 0
                            };
                        } else {
                            tableData[sale.event.stockID][`${seat.productID}-INV`].count = tableData[sale.event.stockID][`${seat.productID}-INV`].count + 1;
                            tableData[sale.event.stockID][`${seat.productID}-INV`].total = tableData[sale.event.stockID][`${seat.productID}-INV`].total + 0;
                        }
                    }
                    else {
                        // Then we can increment the count in productID
                        if (
                            !tableData[sale.event.stockID][seat.productID]
                        ) {
                            // find name of the productID and add it to the tableData

                            let stockProduct = stock.stock.find(r => r.id === seat.productID);

                            let product = {
                                title: { en: seat.selectedOption.title.en, }, price: seat.selectedOption.price
                            };
                            tableData[sale.event.stockID][seat.productID] = {
                                name: product.title.en,
                                count: 1,
                                total: ORGANISATION_OR_VENUE_SALE_CHANNELS.includes(sale.channel) ? stockProduct.price : product.price
                            };
                        } else {
                            let stockProduct = stock.stock.find(r => r.id === seat.productID);

                            let product = {
                                title: { en: seat.selectedOption.title.en, }, price: seat.selectedOption.price
                            };

                            tableData[sale.event.stockID][seat.productID].count = tableData[sale.event.stockID][seat.productID].count + 1;
                            tableData[sale.event.stockID][seat.productID].total = tableData[sale.event.stockID][seat.productID].total + (ORGANISATION_OR_VENUE_SALE_CHANNELS.includes(sale.channel) ? stockProduct.price : product.price);
                        }

                    }

                });
            }
        });
    }

    // console.log(tableData);

    // convert tableData into data grid format
    let tableDataGrid = [];
    Object.keys(tableData).map(sessionEpoch => {
        Object.keys(tableData[sessionEpoch]).map(productID => {
            tableDataGrid.push({
                id: productID,
                name: tableData[sessionEpoch][productID].name,
                count: tableData[sessionEpoch][productID].count,
                total: tableData[sessionEpoch][productID].total,
                remaining: tableData[sessionEpoch][productID].remaining
            });
        });
    });

    // order by count desc
    tableDataGrid.sort((a, b) => {
        return b.count - a.count;
    });

    // let totalRevenue = tableDataGrid.reduce((a, b) => a + b.total, 0);
    let totalRemaining = tableDataGrid.reduce((a, b) => a + (b.remaining ? b.remaining : 0), 0);

    // // extra step for totalRevenue, when commission is extra
    // if (event.isCommissionExtra) {
    //     totalRevenue = totalRevenue + (totalRevenue * event.commissionFee / 100);
    // }

    let totalSale = 0;
    let totalReservedSales = 0;
    let totalSaleCount = data.length;
    let totalSaleSeatsCount = 0;
    let totalServiceFee = 0;

    let totalComputedCommission = 0

    // Organisation or Venue Sales
    let totalSoldByOrganisationOrVenue = 0;
    let totalCountOfSoldByOrganisation = 0;
    let totalCountOfSeatsSoldByOrgansation = 0;
    let totalCountOfInvitationsByOrganisation = 0;
    let totalCommissionForOrganisationSales = 0;
    data.map((sale, i) => {
        if (sale.status == 1) {

            totalSaleSeatsCount = totalSaleSeatsCount + sale.seats.length;

            if (ORGANISATION_OR_VENUE_SALE_CHANNELS.includes(sale.channel)) {

                totalCountOfSoldByOrganisation++;
                totalCountOfSeatsSoldByOrgansation = totalCountOfSeatsSoldByOrgansation + sale.seats.length;
                totalCountOfInvitationsByOrganisation = totalCountOfInvitationsByOrganisation + (sale.isInvitation ? sale.seats.length : 0);

                let saleAmount = sale.paymentTotal;

                let amountToBeAdded = 0;

                amountToBeAdded = (
                    saleAmount
                    - (event.servicefee ? event.servicefee : 0)
                );

                if (event.isCommissionExtra) {
                    let saleCommission = saleAmount * (1 - (100 / (100 + event.commissionFee)));
                    totalCommissionForOrganisationSales += saleCommission;
                    amountToBeAdded = amountToBeAdded - saleCommission;
                }

                totalSoldByOrganisationOrVenue += amountToBeAdded;

            }
            else {
                totalComputedCommission += !event.isCommissionExtra ? (sale.paymentTotal * 100 / event.commissionFee) : (
                    sale.paymentTotal * (1 - (100 / (100 + event.commissionFee)))
                );
            }

            totalSale += sale.paymentTotal - (event.servicefee ? event.servicefee : 0);
            totalServiceFee += (event.servicefee ? event.servicefee : 0);


        } else {
            totalReservedSales += 1
        }
    });

    let totalPriceOfProductsSold = tableDataGrid.reduce((a, b) => a + b.total, 0);
    // totalPriceOfProductsSold = totalPriceOfProductsSold - totalCommissionForOrganisationSales;


    // let subTotal = totalSale - totalRemaining - totalSoldByOrganisationOrVenue;

    // console.log(totalSale, totalSoldByOrganisationOrVenue)
    let subTotal = totalPriceOfProductsSold - totalSoldByOrganisationOrVenue;

    let totalPayable = (
        event.isCommissionExtra ?
            (subTotal - (totalComputedCommission + totalServiceFee)) :
            (subTotal - (totalServiceFee))
    );

    return (
        <div style={{ borderBottom: '2px solid #AAA', margin: 30, marginTop: 0 }}>

            <h3>Sales</h3>
            <DataGridPremium
                rows={tableDataGrid}
                density="compact"
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    pagination: { paginationModel: { pageSize: 100 } },
                }}
                hideFooter={true}
                style={{ flex: 1, width: '100%' }}
                getRowId={(row) => row.id}
                columns={SEATED_COLUMNS}
            />

            {/* right aligned */}
            <div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 50, marginBottom: 50, marginRight: 50 }}>

                    <div>

                        <div>
                            Total Sold: {(data.length - totalReservedSales).toString().concat(' Sales')} {totalReservedSales && " + ".concat(totalReservedSales.toString().concat(' RESV.'))} ({tableDataGrid.reduce((a, b) => a + b.count, 0).toString().concat(' Seats')})
                        </div>

                        <div>
                            Total Price of Products Sold: {totalPriceOfProductsSold.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                        </div>

                        {/* <div style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #AAA' }}> */}
                        {/* total revenue from product price */}
                        {/* <b>Total Revenue: </b> {totalRevenue.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })} */}
                        {/* </div> */}

                        <div style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #AAA' }}>
                            {/* total revenue from product price */}
                            Total Remaining: (Reservation) {totalRemaining.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                        </div>

                        <div>
                            {/* total revenue from product price */}
                            Total Sold By Organisation or Venue: {totalCountOfSoldByOrganisation.toString().concat(' Sales (').concat((totalCountOfSeatsSoldByOrgansation - totalCountOfInvitationsByOrganisation), ' Tic. + ', totalCountOfInvitationsByOrganisation.toString().concat(' Inv.'), ' Seats)')}
                        </div>

                        <div style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #AAA' }}>
                            {/* total sold by org. or venue */}
                            Amount Sold by Organisation or Venue: {totalSoldByOrganisationOrVenue.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                        </div>

                        <div>
                            Gise Kibris Web & Mobile Sales: {totalSaleCount - totalCountOfSoldByOrganisation} Sales ({(totalSaleSeatsCount - totalCountOfSeatsSoldByOrgansation)} Seats)
                        </div>
                        <div>
                            <b>Sub Total: </b> {subTotal.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                        </div>

                        <div style={{ marginTop: 10, paddingTop: 10, borderTop: '1px solid #AAA' }} />

                        <div>
                            <b>Gise Kibris / Commission ({event.isCommissionExtra ? "Extra" : "Inc"}): </b> {event.commissionFee}% / {(totalComputedCommission).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                        </div>

                        {
                            event.servicefee ? (
                                <div>
                                    <b>Gise Kibris / Service Fee: </b> {event.servicefee.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })} / {(totalSaleCount * event.servicefee).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                                </div>
                            ) : (
                                <div>
                                    <b>Gise Kibris / Service Fee: </b> {(0).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                                </div>
                            )
                        }
                        <div style={{ marginTop: 10, paddingTop: 10, borderTop: '1px solid #AAA' }}>
                            {/* total sale with commission */}
                            <b>Total Payable: </b> {totalPayable.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
};

export default SaleReportPage;