import React, { useEffect, useState } from "react";
import { usePermissions, useGetOne } from "react-admin";

// Services
import { db } from "../database/firebase";
import { collection, getDocs, query, orderBy, limit, where } from "firebase/firestore";

// Libs
import { DataGridPremium, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import moment from 'moment';

// UI
import { Select, MenuItem, TextField } from '@mui/material';

// Hooks
import useCategories from "../components/hooks/useCategories";

// Icons
import { Refresh } from '@mui/icons-material';

// Components
import EmptyList from "../components/misc/EmptyList";

// Utils
import { truncateString } from '../utils/misc';

// Constants
const eventTypes = [{ id: 1, name: 'Standard' }, { id: 2, name: 'Area Layout' }, { id: 3, name: 'Seated' },];
const dateTypes = [{ id: 'oneoff', name: 'Single Date' }, { id: 'session', name: 'Sessions' }];

const D_LAST_X_ROWS = 100;
const D_ORDER_ON = 'startdate';
const D_ODER = 'asc';
const D_ENTITY = 'events-v2';
const D_COLUMNS = [
    { field: 'id', headerName: 'ID', width: 100, filterable: false },
    {
        field: 'name', headerName: 'Name', width: 200, filterable: true, renderCell: (params) => { truncateString(params.value, 50) },
        filterOperators: [
            {
                label: 'Contains',
                value: 'contains',
                getApplyFilterFn: (filterItem) => {
                    if (!filterItem.value) {
                        return null;
                    }
                    return (params) => params.value?.toString().toLowerCase().includes(filterItem.value.toLowerCase());
                },
                InputComponent: (props) => (
                    <TextField
                        variant="outlined"
                        size="small"
                        value={props.item.value || ''}
                        onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                        placeholder="Search by name"
                        style={{ width: '100%' }}
                    />
                )
            }
        ],
    },
    {
        field: 'type', headerName: 'Type', width: 150, filterable: true, renderCell: (params) => { return eventTypes.find(t => t.id === params.value)?.name.concat(' / ', dateTypes.find(t => t.id === params.row.dateType)?.name) },
        filterOperators: [
            {
                label: 'Equals',
                value: 'equals',
                getApplyFilterFn: (filterItem) => {
                    if (!filterItem.value) {
                        return null;
                    }
                    return (params) => {
                        const type = eventTypes.find(t => t.id === params.value)?.name;
                        return type === filterItem.value;
                    };
                },
                InputComponent: (props) => {
                    return (
                        <Select
                            variant="outlined"
                            size="small"
                            value={props.item.value || ''}
                            onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                            displayEmpty
                            style={{ width: '100%' }}
                        >
                            <MenuItem value="" disabled>
                                Select type
                            </MenuItem>
                            {eventTypes.map((type) => (
                                <MenuItem key={type.id} value={type.name}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                    );
                }
            }
        ]
    },
    { field: 'venue', headerName: 'Venue', width: 200, filterable: true, renderCell: (params) => <DynamicCell reference="venues" id={params.value} field="name" />, filterable: false },
    {
        field: 'city', headerName: 'City', width: 100, filterable: true, renderCell: (params) => <DynamicCell reference="cities" id={params.row.city} renderFunction={(r) => `${r.name.en}`} />,
        filterOperators: [
            {
                label: 'Equals',
                value: 'equals',
                getApplyFilterFn: (filterItem) => {
                    if (!filterItem.value) {
                        return null;
                    }
                    return (params) => params.value?.toLowerCase() === filterItem.value.toLowerCase();
                },
                InputComponent: (props) => (
                    <Select
                        variant="outlined"
                        size="small"
                        value={props.item.value || ''}
                        onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                        displayEmpty
                        style={{ width: '100%' }}
                    >
                        <MenuItem value="" disabled>
                            Select city
                        </MenuItem>
                        {['nicosia', 'famagusta', 'iskele', 'kyrenia', 'larnaca', 'lefke', 'limassol', 'morphou', 'paphos'].map((city) => (
                            <MenuItem key={city} value={city}>
                                {city.charAt(0).toUpperCase() + city.slice(1)}
                            </MenuItem>
                        ))}
                    </Select>
                )
            }
        ]
    },
    {
        field: 'category', headerName: 'Category', width: 125, filterable: true, renderCell: (params) => <DynamicCell reference="categories" id={params.row.category} renderFunction={(r) => `${r.name.en}`} />,
        filterOperators: [
            {
                label: 'Equals',
                value: 'equals',
                getApplyFilterFn: (filterItem) => {
                    if (!filterItem.value) {
                        return null;
                    }
                    return (params) => params.value === filterItem.value; // Match by DB ID
                },
                InputComponent: (props) => {
                    const categories = useCategories(); // Fetch categories here

                    return (
                        <Select
                            variant="outlined"
                            size="small"
                            value={props.item.value || ''}
                            onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                            displayEmpty
                            style={{ width: '100%' }}
                        >
                            <MenuItem value="" disabled>
                                Select category
                            </MenuItem>
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.label}
                                </MenuItem>
                            ))}
                        </Select>
                    );
                }
            }
        ]
    },
    { field: 'startdate', headerName: 'Starts at', width: 100, filterable: true, renderCell: (params) => `${moment(params.row.startdate.toDate()).format('DD/MM/YY / HH:mm')}`, filterable: false },
    // enddate
    { field: 'enddate', headerName: 'Ends at', width: 100, filterable: true, renderCell: (params) => `${moment(params.row.enddate.toDate()).format('DD/MM/YY / HH:mm')}`, filterable: false },
    {
        field: 'verified.active', headerName: 'Verified / Visible', width: 150, renderCell: (params) => {
            let r = params.row;
            // if verified and active, green tick for true, red cross for false, side by side, separated with a /
            let verified = r.isVerified ? 'Yes' : 'No';
            let active = r.isActive ? 'Yes' : 'No';

            return (
                <div style={{ display: 'flex', width: 80, paddingLeft: 5 }}>
                    <span style={{ color: r.isVerified ? 'green' : 'red' }}>{verified}</span> &nbsp; / &nbsp;
                    <span style={{ color: r.isActive ? 'green' : 'red' }}>{active}</span>
                </div>
            )
        },
        filterOperators: [
            {
                label: 'Equals',
                value: 'equals',
                getApplyFilterFn: (filterItem) => {
                    if (filterItem.value === undefined) {
                        return null;
                    }
                    return (params) => params.row.isVerified === (filterItem.value === 'true'); // Convert string to boolean
                },
                InputComponent: (props) => (
                    <Select
                        variant="outlined"
                        size="small"
                        value={props.item.value || ''}
                        onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                        displayEmpty
                        style={{ width: '100%' }}
                    >
                        <MenuItem value="" disabled>
                            Select verification
                        </MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                ),
            },
        ],
    },
    {
        field: 'status', headerName: 'Status', width: 150, filterable: false, renderCell: (params) => {
            let r = params.row;
            let bgColor = 'green';
            let status = 'Happening now';

            if (moment().isAfter(moment(r.enddate))) { bgColor = 'grey'; status = 'Past' }

            if (r.isCancelled) {
                bgColor = 'red';
                status = 'Cancelled';
            }

            return (
                <div style={{ backgroundColor: bgColor, textAlign: 'center', borderRadius: 5, padding: 3 }}>
                    <span style={{ color: 'white', textAlign: 'center' }}>{status}</span>
                </div>
            )
        }
    }

]
const INITIALLY_VISIBLE_COLUMNS = { id: false };

const DataPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState(D_COLUMNS);
    const { permissions } = usePermissions();

    let listFilter = {};

    let isManager = false;
    let isAdmin = false;
    if (permissions.crole && permissions.crole === "organisationCompanyManager" && permissions.rid) {
        isManager = true;
        listFilter.organisationCompanies = permissions.rid;
    }
    if (permissions.crole && permissions.crole === "venueManager" && permissions.rid) {
        isManager = true;
        listFilter.venue = permissions.rid;
    }
    if (permissions.crole && permissions.crole === "admin") isAdmin = true;

    const getData = async () => {

        setIsLoading(true);

        const collectionRef = collection(db, D_ENTITY);
        let q = null;
        if (isManager) {
            if (permissions.crole === "organisationCompanyManager") q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), limit(D_LAST_X_ROWS), where('startdate', '>=', new Date()), where('organisationCompanies', "array-contains", permissions.rid));
            if (permissions.crole === "venueManager") q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), limit(D_LAST_X_ROWS), where('startdate', '>=', new Date()), where('venue', '==', permissions.rid));
        } else {
            q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), limit(D_LAST_X_ROWS),
                where('startdate', '<=', new Date())
            );
        }

        const dataSnapshot = await getDocs(q);

        if (dataSnapshot) {

            let data = [];

            dataSnapshot.forEach((d) => {
                let docData = d.data();
                
                // filter by enddate
                if(docData.enddate.toDate() >= new Date()) {
                    data.push({ id: d.id, ...docData });
                }
            });

            setData(data);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    if (!permissions) return <EmptyList mode="loading" /> // loading permissions
    if (isLoading) return <EmptyList mode="loading" /> //loading data

    if (permissions.crole && permissions.crole !== "admin" && permissions.crole !== "operator" && !permissions.rid) return <EmptyList customMessage="You don't the right permission set to view this page. Please contact GişeKıbrıs if you believe this is an error." noCreate={true} />;


    const CustomTableHeader = () => {
        return (
            <GridToolbarContainer>
                {/* <GridToolbarColumnsButton /> */}
                {/* <GridToolbarFilterButton /> */}
                <GridToolbarDensitySelector />
                {/* <GridToolbarExport csvOptions={{ disableToolbarButton: true }} printOptions={{ hideToolbar: true }} excelOptions={{ fileName: `gisekibris-sales-${moment().format('LL-LT')}.xlsx` }} /> */}
                <RefreshButton refreshData={getData} />
            </GridToolbarContainer>
        );
    }

    return (
        <div>
            <div style={{ marginLeft: 20 }}>
                <h1>Active Events List / Happening now</h1>
                <span>You can find below the list of events which are happening right now, within your permissions set, ordered ascending on date/time.</span>
            </div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', margin: 0, padding: 0, width: '100%' }}>
                <div className="container" style={{ padding: 20, width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <DataGridPremium
                                rows={data}
                                density="compact"
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: INITIALLY_VISIBLE_COLUMNS
                                    },
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}

                                // apiRef={apiRef}
                                // onRowClick={(params) => {
                                //     // show detail panel
                                //     apiRef.current.toggleDetailPanel(params.id);

                                // }}
                                // getDetailPanelContent={({ row }) => getDetailPanelContent(row)}
                                // getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.

                                onColumnVisibilityModelChange={(model) => {
                                    setColumns(
                                        columns.map((column) => ({
                                            ...column,
                                            hide: !model[column.field],
                                        }))
                                    );
                                }}
                                style={{ flex: 1, width: '100%' }}
                                getRowId={(row) => row.id}
                                sx={{
                                    // disable cell selection style
                                    '.MuiDataGrid-cell:focus': {
                                        outline: 'none'
                                    },
                                    // pointer cursor on ALL rows
                                    '& .MuiDataGrid-row:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                onRowClick={(params) => {
                                    window.location.href = `/#/events-v2/${params.id}/show`;
                                }}
                                columns={columns}
                                // hideFooter={!eventID}
                                slots={{
                                    toolbar: CustomTableHeader
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DataPage;



// UTILS

const RefreshButton = ({ refreshData }) => {
    return (
        <button
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'black',
                cursor: 'pointer',
                fontSize: 10,
                fontWeight: 500,
                outline: 'none',
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}
            onClick={refreshData}
        >
            {/* refresh icon */}
            <Refresh style={{ fontSize: 16, marginRight: 5 }} />
            REFRESH
        </button>
    );
};

const PageMessage = ({ message }) => (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', margin: 0, padding: 0, width: '100%' }}>
        <div className="container" style={{ padding: 20, width: '100%' }}>
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', height: '100%', fontSize: 14 }}>
                    {message}
                </div>
            </div>
        </div>
    </div>
);

const DynamicCell = ({ reference, id, field, suffix, renderFunction }) => {
    const { data, isLoading, error } = useGetOne(
        reference,
        { id }
    );

    if (isLoading) return <span>Loading...</span>
    if (error) return <span>Error</span>

    if (!field) {
        if (renderFunction) return <span>{renderFunction(data)}</span>
        else return <span>{data}</span>
    }

    if (field.indexOf('+') > -1) {
        let fields = field.split('+');
        return <span>{data[fields[0]]} {data[fields[1]]}</span>
    }
    else {
        if (renderFunction) {
            // console.log('renderFunction', renderFunction)
            // console.log('data[field]', data[field])
            return <span>{renderFunction(data[field])}</span>
        }
        else return <span>{data[field]}{suffix}</span>
    }
}
