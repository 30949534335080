import { Select, MenuItem } from '@mui/material';

import useCategories from "../hooks/useCategories";

export default [
    {
        label: 'Equals',
        value: 'equals',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
                return null;
            }
            return (params) => params.value === filterItem.value; // Match by DB ID
        },
        InputComponent: (props) => {
            const categories = useCategories(); // Fetch categories here

            return (
                <Select
                    variant="outlined"
                    size="small"
                    value={props.item.value || ''}
                    onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                    displayEmpty
                    style={{ width: '100%' }}
                >
                    <MenuItem value="" disabled>
                        Select category
                    </MenuItem>
                    {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                            {category.label}
                        </MenuItem>
                    ))}
                </Select>
            );
        }
    }
]