import { Select, MenuItem } from '@mui/material';

export default [
    {
        label: 'Equals',
        value: 'equals',
        getApplyFilterFn: (filterItem) => {
            if (filterItem.value === undefined) {
                return null;
            }
            return (params) => params.row.isVerified === (filterItem.value === 'true'); // Convert string to boolean
        },
        InputComponent: (props) => (
            <Select
                variant="outlined"
                size="small"
                value={props.item.value || ''}
                onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                displayEmpty
                style={{ width: '100%' }}
            >
                <MenuItem value="" disabled>
                    Select verification
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
            </Select>
        ),
    },
];