import { Select, MenuItem } from '@mui/material';

export default [
    {
        label: 'Equals',
        value: 'equals',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
                return null;
            }
            return (params) => params.value?.toLowerCase() === filterItem.value.toLowerCase();
        },
        InputComponent: (props) => (
            <Select
                variant="outlined"
                size="small"
                value={props.item.value || ''}
                onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                displayEmpty
                style={{ width: '100%' }}
            >
                <MenuItem value="" disabled>
                    Select city
                </MenuItem>
                {['nicosia', 'famagusta', 'iskele', 'kyrenia', 'larnaca', 'lefke', 'limassol', 'morphou', 'paphos'].map((city) => (
                    <MenuItem key={city} value={city}>
                        {city.charAt(0).toUpperCase() + city.slice(1)}
                    </MenuItem>
                ))}
            </Select>
        )
    }
]