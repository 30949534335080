import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-admin';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { EditOutlined as EditIcon, FormatColorFill, PhotoSizeSelectActual, FormatColorText } from '@mui/icons-material';
import CopyAllOutlined from '@mui/icons-material/CopyAllOutlined';
import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import Konva from 'konva';
import { Stage, Layer, Rect, Circle, Text, Group, Line, Image } from 'react-konva';
import { useFormContext } from 'react-hook-form';

// Constants
const width = 2000;
const height = 2000;
const blockSnapSize = 35;
const standardEventTicketGroups = ['deal', 'general', 'guestlist', 'vip', 'extras', 'invitation'];

const SeatingPlanCanvas = ({ record, isAssignMode, fullLayout, isViewOnly, dataProvider, tickets, ticketsID, isCreateMode, layoutType, fullLayoutID }) => {

    const { setValue } = useFormContext();

    const [selectedShapes, setSelectedShapes] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [selectedAreaProducts, setSelectedAreaProducts] = useState([]);
    const [layoutLoaded, setLayoutLoaded] = useState(false);
    const stageRef = useRef(null);
    const layerRef = useRef(null);
    const shadowRectRef = useRef(null);

    const [useTickets, setUseTickets] = useState(tickets);
    
    const setField = isCreateMode ? 'layout' : 'layoutJSON';

    useEffect(() => {
        if (isViewOnly && record) {
            loadLayoutLocal(record.layout);
        } else if (isAssignMode && record) {
            if (fullLayout) {
                setTimeout(() => {
                    loadLayoutLocal(record);
                    addSelectionTool();
                    setSelectedShapes([]);
                }, 500);
            } else if (fullLayoutID) {
                (async () => {
                    await loadLayoutJSONByID(record);
                    tickets = await loadTicketsByID(ticketsID);

                    setUseTickets(tickets);

                    setTimeout(() => {
                        addSelectionTool();
                        setSelectedShapes([]);
                    }, 250);
                })();
            }
            else {
                (async () => {
                    await loadLayout(record);
                    setTimeout(() => {
                        addSelectionTool();
                        setSelectedShapes([]);
                    }, 250);
                })();
            }
        } else {
            addGridLayer();
            addSelectionTool();
            addShadowRect();
        }
    }, [record, isAssignMode, fullLayout, isViewOnly]);

    const loadLayout = async (layoutId) => {
        const { data } = await dataProvider.getOne('layouts', { id: layoutId });
        if (data && data.layout) {
            let stageJSON = JSON.parse(data.layout);
            stageRef.current = Konva.Node.create(stageJSON, 'container');
            stageRef.current.width(width);
            stageRef.current.height(height);
            layerRef.current = stageRef.current.find('Layer')[findIndexWithTickets(stageJSON)];
            layerRef.current.find('Group').forEach(group => group.draggable(false));
            if (layerRef.current.find('Image').length > 0) {
                let url = layerRef.current.find('Image')[0].attrs.source;
                if (url && url !== '') {
                    addBackgroundImageForDisplay(url);
                }
            }
            stageRef.current.draw();
            setLayoutLoaded(true);
        }
    };

    const loadLayoutJSONByID = async (layoutID) => {
        const { data } = await dataProvider.getOne('eventLayouts', { id: layoutID });
        if (data && data.layoutJSON) {
            let stageJSON = JSON.parse(data.layoutJSON);
            stageRef.current = Konva.Node.create(stageJSON, 'container');

            stageRef.current.width(width);
            stageRef.current.height(height);

            layerRef.current = stageRef.current.find('Layer')[findIndexWithTickets(stageJSON)];
            layerRef.current.find('Group').forEach(group => group.draggable(false));
            if (layerRef.current.find('Image').length > 0) {
                let url = layerRef.current.find('Image')[0].attrs.source;

                if (url && url !== '') {
                    // addBackgroundImageForDisplay(url);
                }
            }
            stageRef.current.draw();
            setLayoutLoaded(true);
        }
    };


    const loadTicketsByID = async (ticketsID) => {
        const { data } = await dataProvider.getOne('stocks', { id: ticketsID });
        if (data && data.stock) {
            return data.stock;
        }
    };

    const loadLayoutLocal = (layout) => {
        if (layout && JSON.parse(layout)) {
            let stageJSON = JSON.parse(layout);
            stageRef.current = Konva.Node.create(stageJSON, 'container');
            stageRef.current.width(width);
            stageRef.current.height(height);
            layerRef.current = stageRef.current.find('Layer')[findIndexWithTickets(stageJSON)];
            layerRef.current.find('Group').forEach(group => group.draggable(false));
            if (layerRef.current.find('Image').length > 0) {
                let url = layerRef.current.find('Image')[0].attrs.source;
                if (url && url !== '') {
                    addBackgroundImageForDisplay(url);
                }
            }
            stageRef.current.draw();
            setLayoutLoaded(true);
        }
    };

    const addGridLayer = () => {
        var gridLayer = new Konva.Layer();
        var padding = blockSnapSize;
        for (var i = 0; i < width / padding; i++) {
            gridLayer.add(new Konva.Line({
                points: [Math.round(i * padding) + 0.5, 0, Math.round(i * padding) + 0.5, height],
                stroke: '#ddd',
                strokeWidth: 1,
            }));
        }
        gridLayer.add(new Konva.Line({ points: [0, 0, 10, 10] }));
        for (var j = 0; j < height / padding; j++) {
            gridLayer.add(new Konva.Line({
                points: [0, Math.round(j * padding), width, Math.round(j * padding)],
                stroke: '#ddd',
                strokeWidth: 0.5,
            }));
        }
        if (stageRef.current) {
            stageRef.current.add(gridLayer);
        }
    };

    const addShadowRect = () => {
        var shadowRectangle = new Konva.Rect({
            x: 0,
            y: 0,
            width: blockSnapSize,
            height: blockSnapSize,
            fill: '#FF7B17',
            opacity: 0.6,
            stroke: '#CF6412',
            strokeWidth: 3,
            dash: [20, 2]
        });
        shadowRectangle.hide();
        if (layerRef.current) {
            layerRef.current.add(shadowRectangle);
            shadowRectRef.current = shadowRectangle;
        }
    };

    const addSelectionTool = () => {

        let selectionRect;
        let stage = stageRef.current;
        let layer = layerRef.current;

        if (!stage || !layer) return;

        stage.on('mousedown', (e) => {

            if (selectionRect) {
                selectionRect.destroy();
                selectionRect = null;
            }

            // before all let's fill everything with white, all seats in the stage
            const groups = layer.getChildren(node => node.getClassName() === 'Group');
            const seats = groups.filter(rect => rect.getClassName() === 'Group');
            seats.forEach(seat => {
                // if they have a ticket leave them as they are
                if (seat.attrs.ticket) {
                    seat.children[0].fill(stringToColour(seat.attrs.ticket));
                    seat.children[1].fill('white');
                }
                else if (seat.attrs.tickets) {
                    seat.children[0].fill('green');
                }
                else {
                    seat.children[0].fill(layoutType === 'seating' ? 'white' : 'transparent');
                    seat.children[1].fill('black');
                }
            });

            // then let's get on with the rest
            const selectedSeats = selectedShapes;
            console.log('selectedSeats manipulation started');

            selectedSeats.forEach(seat => {

                // console.log('seat', seat.children[1].attrs.text);
                // console.log('seat');

                if (seat && seat.attrs && (seat.attrs.ticket || seat.attrs.tickets)) {
                    
                    if (seat.attrs.ticket) {

                        console.log("There is ticket attr for the seat", seat.attrs.ticket);

                        seat.children[0].fill(stringToColour(seat.attrs.ticket));

                        if (seat.children[0].attrs.prevBGColor && seat.children[0].attrs.prevBGColor.length !== 0 && !isAssignMode) {

                            console.log("There is prevBGColor for the seat and we are NOT in the assignMode", seat.children[0].attrs.prevBGColor);

                            seat.children[0].fill(seat.children[0].attrs.prevBGColor);
                            seat.children[0].setAttr('prevBGColor', null);

                        } else {

                            console.log("There is no prevBGColor for the seat, just fill the TEXT with white, because there is a ticket set so we need white text.");
                            seat.children[1].fill('white');

                        }
                    }

                    if (seat.attrs.tickets) {
                        console.log("There is multi tickets attr for the seat");
                        seat.children[0].fill("green");
                    }

                } else {

                    console.log("No ticket or tickets attr for the seat");
                    // console.log(seat.children[0].attrs.prevBGColor);

                    if (seat.children[0].attrs.prevBGColor && seat.children[0].attrs.prevBGColor.length !== 0) {
                        console.log("There is prevBGColor for the seat", seat.children[0].attrs.prevBGColor);

                        if (seat.children[0].attrs.prevBGColor === 'white' || seat.children[0].attrs.prevBGColor === '#FFF' || seat.children[0].attrs.prevBGColor === 'transparent') {
                            console.log("Prev BG color is white or #FFF or we are in createMode, so we are going to fill it with that.");
                            seat.children[0].fill(seat.children[0].attrs.prevBGColor);

                            // CONSIDER DOING THIS FOR WHITE AND FFF TOO????
                            if (seat.children[0].attrs.prevBGColor === 'transparent') {
                                console.log("YES IT IS");
                                // 
                                seat.children[0].setAttr('prevBGColor', 'transparent');
                            } else {
                                console.log("NO ITS NOT");
                                seat.children[0].setAttr('prevBGColor', null);
                            }

                        } else {
                            console.log("Prev BG color is not white or #FFF:", seat.children[0].attrs.prevBGColor);
                            // seat.children[0].fill('#FFF');
                        }

                    }

                    else {
                        console.log("There is no prevBGColor for the seat, just fill white");
                        seat.children[0].fill('#FFF');
                    }
                }
                if (seat.attrs.bgColor) {
                    console.log("There is bgColor attr for the seat, fill it with that", seat.attrs.bgColor);
                    seat.children[0].fill(seat.attrs.bgColor);
                }
            });

            console.log('ended mousedown selectedSeats manipulation, now creating a new selection rect');

            selectionRect = new Konva.Rect({
                x: e.evt.offsetX,
                y: e.evt.offsetY,
                width: 0,
                height: 0,
                fill: 'rgba(0,0,255,0.5)',
                stroke: 'blue',
                strokeWidth: 1,
                listening: false
            });
            layer.add(selectionRect);
        });

        stage.on('mousemove', (e) => {
            if (selectionRect) {
                selectionRect.width(e.evt.offsetX - selectionRect.x());
                selectionRect.height(e.evt.offsetY - selectionRect.y());
                layer.batchDraw();
            }
        });

        stage.on('mouseup', (e) => {
            if (selectionRect) {
                const groups = layer.getChildren(node => node.getClassName() === 'Group');
                const seats = groups.filter(rect => Konva.Util.haveIntersection(rect.getClientRect(), selectionRect.getClientRect()));
                seats.forEach(seat => {
                    const prevBG = seat.children[0].fill();
                    seat.children[0].setAttr('prevBGColor', prevBG)
                    setTimeout(() => {
                        seat.children[0].fill('#FF7B17')
                    }, 150);
                });
                setSelectedShapes(seats);
                selectionRect.destroy();
                layer.batchDraw();
                setValue(setField, stage.toJSON());
            }
        });
    };

    const addShape = (event) => {
        event.preventDefault();
        let lastX = 0;
        let lastY = 0;
        const lastGroup = layerRef.current.find("Group").pop();
        if (lastGroup) {
            lastX = lastGroup.x();
            lastY = lastGroup.y();
        }
        const label = new Konva.Text({
            text: '1',
            fontSize: 12,
            x: 7,
            y: 7,
            fontFamily: 'Arial',
            fill: 'black'
        });

        if (lastGroup) {
            const labelNum = parseInt(lastGroup.children[1].attrs.text) || 0;
            label.text(labelNum + 1);
        }

        const shape = new Konva.Rect({
            name: 'drawn',
            width: blockSnapSize,
            height: blockSnapSize,
            scaleX: 0.9,
            scaleY: 0.9,
            x: 2,
            y: 2,
            fill: '#FFF',
            stroke: 'black',
            strokeWidth: 1.5,
        });
        const seatGroup = new Konva.Group({
            x: lastX + blockSnapSize,
            y: lastY,
            draggable: true
        });
        seatGroup.add(shape);
        seatGroup.add(label);
        seatGroup.on('dragstart', (e) => {
            shadowRectRef.current.show();
            shadowRectRef.current.moveToTop();
            seatGroup.moveToTop();
        });
        seatGroup.on('dragmove', (e) => {
            shadowRectRef.current.position({
                x: Math.round(seatGroup.x() / blockSnapSize) * blockSnapSize,
                y: Math.round(seatGroup.y() / blockSnapSize) * blockSnapSize
            });
            stageRef.current.batchDraw();
            const scrollContainer = document.getElementById('scrollContainer');
            const containerRect = scrollContainer.getBoundingClientRect();
            const scrollBy = 30;
            if (containerRect.left > 0 && e.evt.clientX < (containerRect.left + 50)) {
                scrollContainer.scrollLeft -= scrollBy;
            }
            if (e.evt.clientX > window.innerWidth - 50) {
                scrollContainer.scrollLeft += scrollBy;
            }
            if (containerRect.top > 0 && e.evt.clientY < (containerRect.top + 50)) {
                scrollContainer.scrollTop -= scrollBy;
            }
            if (e.evt.clientY > window.innerHeight - 50) {
                scrollContainer.scrollTop += scrollBy;
            }
        });
        seatGroup.on('dragend', (e) => {
            seatGroup.position({
                x: Math.round(seatGroup.x() / blockSnapSize) * blockSnapSize,
                y: Math.round(seatGroup.y() / blockSnapSize) * blockSnapSize
            });
            stageRef.current.batchDraw();
            shadowRectRef.current.hide();
        });
        layerRef.current.add(seatGroup);
        layerRef.current.draw();
        setValue(setField, stageRef.current.toJSON());
        return false;
    };

    const addArea = (event, type) => {
        event.preventDefault();
        let size = prompt("Please enter the width of the area", "35");
        let heightRectangle = null;
        if (size === null || size === "") return false;
        size = parseInt(size);
        if (type === 'rectangle') {
            heightRectangle = prompt("Please enter the height of the area", "35");
            if (heightRectangle === null || heightRectangle === "") return false;
            heightRectangle = parseInt(heightRectangle);
        }
        let lastX = 50;
        let lastY = 50;
        const lastGroup = layerRef.current.find("Group").pop();
        if (lastGroup) {
            lastX = lastGroup.x();
            lastY = lastGroup.y();
        }
        let shape;
        if (type === 'circle') {
            shape = new Konva.Circle({
                name: 'drawn',
                radius: size,
                x: 0,
                y: 0,
                fill: 'transparent',
                stroke: 'black',
                strokeWidth: 1.5,
            });
        }
        if (type === 'rectangle') {
            shape = new Konva.Rect({
                name: 'drawn',
                width: size,
                height: heightRectangle,
                x: 0,
                y: 0,
                fill: 'transparent',
                stroke: 'black',
                strokeWidth: 1.5,
            });
        }
        const label = new Konva.Text({
            text: '1',
            fontSize: 12,
            x: 7,
            y: 7,
            fontFamily: 'Arial',
            fill: 'black'
        });
        if (lastGroup) {
            const labelNum = parseInt(lastGroup.children[1].attrs.text) || 0;
            label.text(labelNum + 1);
        }
        const areaGroup = new Konva.Group({
            x: lastX + size,
            y: lastY,
            draggable: true
        });
        areaGroup.add(shape);
        areaGroup.add(label);
        areaGroup.setAttr('selectable', true);
        layerRef.current.add(areaGroup);
        layerRef.current.draw();
        return false;
    };

    const addBackgroundImage = (event) => {
        event.preventDefault();
        let url = prompt("Please enter the URL of the image", "");
        if (url === null || url === "") return false;
        const image = new window.Image();
        image.src = url;
        image.onload = () => {
            const konvaImage = new Konva.Image({
                image: image,
                x: 0,
                y: 0,
                width: image.width,
                height: image.height
            });
            konvaImage.setAttr('source', url);
            layerRef.current.add(konvaImage);
            layerRef.current.draw();
        };
        return false;
    };

    const addBackgroundImageForDisplay = (url) => {
        const image = new window.Image();
        image.src = url;
        const layer = new Konva.Layer();
        image.onload = () => {
            const konvaImage = new Konva.Image({
                image: image,
                x: 0,
                y: 0,
                width: image.width,
                height: image.height,
            });
            layer.add(konvaImage);
            layer.draw();
            layer.setZIndex(-10);
            stageRef.current.add(layer);
            layerRef.current.moveToTop();
        }
    };

    const removeBackgroundImage = (event) => {
        event.preventDefault();
        const image = layerRef.current.find('Image').pop();
        if (image) {
            image.destroy();
            layerRef.current.draw();
        }
        setSelectedShapes([]);
    };

    const deleteShapes = (shapes) => {
        shapes.forEach(shape => shape.destroy());
        layerRef.current.draw();
        setSelectedShapes([]);
    };

    const cloneShape = (shape) => {
        const label = window.prompt('Enter a label for the cloned area:', shape.children[1].attrs.text);
        if (label === null) return;
        const shapes = layerRef.current.find('Group');
        const labels = shapes.map(shape => shape.children[1].attrs.text);
        if (labels.includes(label)) {
            alert('Label already exists, it must be unique');
            return;
        }
        const clonedShape = shape.clone({
            x: shape.x() + 10,
            y: shape.y() + 10
        });
        clonedShape.children[1].text(label);
        layerRef.current.add(clonedShape);
        setSelectedShapes([clonedShape]);
        layerRef.current.draw();
    };

    const changeWidthHeight = (shapes) => {
        const RectShape = shapes[0].find('Rect')[0];
        const CircleShape = shapes[0].find('Circle')[0];
        if (RectShape) {
            const newWidth = window.prompt('Enter new width:', RectShape.width().toString());
            const newHeight = window.prompt('Enter new height:', RectShape.height().toString());
            if (newWidth !== null && newHeight !== null) {
                RectShape.width(parseInt(newWidth));
                RectShape.height(parseInt(newHeight));
                layerRef.current.draw();
            }
        }
        if (CircleShape) {
            const newRadius = window.prompt('Enter new radius:', CircleShape.radius().toString());
            if (newRadius !== null) {
                CircleShape.radius(parseInt(newRadius));
                layerRef.current.draw();
            }
        }
    };

    const renameLabel = (shape) => {
        const newText = window.prompt('Enter new ID for the seat:', shape.children[1].attrs.text);
        if (newText !== null) {
            const existingLabel = layerRef.current.find('Text').find(label => label.attrs.text === newText);
            if (existingLabel) {
                alert('ID must be unique.');
            } else {
                shape.children[1].text(newText);
                layerRef.current.draw();
                setValue(setField, stageRef.current.toJSON());
            }
        }
    };

    const changeBgColor = (shapes) => {
        const color = window.prompt('Enter new color:', shapes[0].children[0].attrs.fill);
        if (color !== null) {
            shapes.forEach(shape => {
                shape.children[0].fill(color);
                shape.setAttr('bgColor', color);
            });
            layerRef.current.draw();
        }
    };

    const changeFgColor = (shapes) => {
        const color = window.prompt('Enter new color:', shapes[0].children[1].attrs.fill);
        if (color !== null) {
            shapes.forEach(shape => {
                shape.children[1].fill(color);
                shape.setAttr('fgColor', color);
            });
            layerRef.current.draw();
        }
    };

    const assignTicket = (shapes) => {
        if (selectedTicket) {
            const ticket = useTickets.find(ticket => ticket.id === selectedTicket);
            shapes.forEach(shape => {
                shape.setAttr('ticket', ticket.id);
                shape.children[0].fill(stringToColour(ticket.id));
                shape.children[1].fill('white');
            });
            layerRef.current.draw();
            setSelectedTicket(null);
            setValue(setField, stageRef.current.toJSON());
        } else {
            alert('Please select a product first. If there is none, add a new product.');
        }
    };

    const assignProductsToArea = (shapes) => {
        if (selectedAreaProducts.length > 0) {
            shapes.forEach(shape => {
                shape.setAttr('tickets', JSON.stringify(selectedAreaProducts));
                shape.children[0].fill('green');
            });
            layerRef.current.draw();
            setSelectedAreaProducts([]);
            setValue(setField, stageRef.current.toJSON());
        } else {
            alert('Please select a product first. If there is none, add a new product.');
        }
    };

    const stringToColour = (idString) => {
        let idNumber = parseInt(idString, 36);
        let red = (idNumber & 0xFF0000) >> 16;
        let green = (idNumber & 0x00FF00) >> 8;
        let blue = idNumber & 0x0000FF;
        let luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
        if (luminance > 0.7) {
            red = Math.floor(red * 0.7);
            green = Math.floor(green * 0.7);
            blue = Math.floor(blue * 0.7);
        }
        return "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1);
    };

    return (
        <div style={{ display: 'flex' }}>
            {
                !isViewOnly && (
                    <div style={{ marginLeft: '20px', minWidth: '200px', marginRight: 20 }}>
                        {
                            isAssignMode && (
                                <div style={{ border: '1px solid #AAA', padding: 5 }}>
                                    <h3>Stats</h3>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ marginBottom: 10 }}>
                                            <span>Total {layoutType === 'seating' ? 'Seats' : 'Areas'}:</span>
                                            <span> {layerRef.current && layerRef.current.find('Group').length}</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                                            {
                                                layoutType !== 'seating' && (
                                                    <span style={{ width: 20, height: 20, backgroundColor: 'green', display: 'inline-block', marginRight: 5 }}></span>
                                                )
                                            }
                                            <span>Assigned {layoutType === 'seating' ? 'Seats' : 'Areas'}: &nbsp;</span>
                                            <span> {layerRef.current && layerRef.current.find('Group').filter(group => group.attrs.ticket || group.attrs.tickets).length}</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {
                                                layoutType !== 'seating' && (
                                                    <span style={{ width: 20, height: 20, backgroundColor: '#FFF', border: '1px solid black', display: 'inline-block', marginRight: 5 }}></span>
                                                )
                                            }
                                            <span>Unassigned {layoutType === 'seating' ? 'Seats' : 'Areas'}:&nbsp;</span>
                                            <span> {layerRef.current && layerRef.current.find('Group').filter(group => !group.attrs.ticket && !group.attrs.tickets).length}</span>
                                        </div>
                                        {
                                            layoutType === 'seating' && <h4>Assigned Products/Tickets:</h4>
                                        }
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {
                                                layoutType === 'seating' ? (
                                                    useTickets && useTickets.length > 0 && useTickets.map(ticket => {
                                                        const assignedSeats = layerRef.current && layerRef.current.find('Text').filter(label => label.parent.attrs.ticket === ticket.id);
                                                        return (
                                                            <div key={ticket.id} style={{ display: 'flex', alignContent: 'center' }}>
                                                                <span>
                                                                    <span style={{ backgroundColor: stringToColour(ticket.id), width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                                                                </span>
                                                                <span>{ticket.title.tr}:</span>
                                                                <span>{assignedSeats && assignedSeats.length > 0 ? assignedSeats.length : 0}</span>
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            selectedShapes.length > 0 ? (

                                <React.Fragment>
                                    {(layoutType === 'seating') && (
                                        <div style={{ border: '1px solid #AAA', padding: 5, marginTop: 20 }}>
                                            <h3>Selected:</h3>
                                            <h4>{selectedShapes.length} {layoutType === 'seating' ? 'seats' : 'areas'}</h4>
                                            <div style={{ maxWidth: 150 }}>
                                                {selectedShapes.map((shape, index) => {
                                                    let label = shape.children[1].attrs.text;
                                                    return label.concat(index + 1 === selectedShapes.length ? '' : ', ');
                                                })}
                                            </div>
                                            {
                                                !isAssignMode ? (
                                                    <div style={{ flexDirection: 'column' }}>
                                                        <h4>Create Actions:</h4>
                                                        <Button onClick={(event) => {
                                                            event.preventDefault();
                                                            deleteShapes(selectedShapes);
                                                        }} label="Delete" size="medium" startIcon={<DeleteIcon />} />
                                                        {
                                                            selectedShapes.length === 1 && (
                                                                <div>
                                                                    <Button onClick={(event) => {
                                                                        event.preventDefault();
                                                                        renameLabel(selectedShapes[0]);
                                                                    }} label="Rename Seat ID" size="medium" startIcon={<EditIcon />} />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <div style={{ flexDirection: 'column' }}>
                                                        <h4>Assign Tickets:</h4>
                                                        <FormControl variant="outlined">
                                                            <InputLabel id="lblid123">Ticket</InputLabel>
                                                            <Select
                                                                labelId="lblid123"
                                                                value={selectedTicket ? selectedTicket : ''}
                                                                onChange={(event) => setSelectedTicket(event.target.value)}
                                                                label="Ticket"
                                                                style={{ minWidth: 150 }}
                                                            >
                                                                {useTickets.map(ticket => (
                                                                    <MenuItem key={ticket.id} value={ticket.id}>{ticket.title.tr}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <Button onClick={(event) => {
                                                                event.preventDefault();
                                                                assignTicket(selectedShapes);
                                                            }} label="Assign" size="medium" startIcon={<EditIcon />} style={{ display: 'block' }} />
                                                            <Button onClick={(event) => {
                                                                event.preventDefault();
                                                                selectedShapes.forEach(shape => {
                                                                    shape.setAttr('ticket', null);
                                                                    shape.children[0].fill('white');
                                                                    shape.children[1].fill('black');
                                                                });
                                                                layerRef.current.draw();
                                                                setValue(setField, stageRef.current.toJSON());
                                                            }} label="Remove" size="medium" startIcon={<DeleteIcon />} style={{ display: 'block' }} />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )}
                                    {(layoutType === 'area') && (
                                        <div style={{ border: '1px solid #AAA', padding: 5, marginTop: 20 }}>
                                            <h3>Selected:</h3>
                                            <h4>{selectedShapes.length.toString().concat(' ', selectedShapes.length > 1 ? 'areas' : 'area')}</h4>
                                            <div style={{ maxWidth: 150 }}>
                                                {/* {selectedShapes.map((shape, index) => {
                                                    let label = shape.attrs.name;
                                                    return label.concat(index + 1 === selectedShapes.length ? '' : ', ');
                                                })} */}
                                            </div>

                                            {
                                                !isAssignMode ? (
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                            <Button onClick={(event) => {
                                                                event.preventDefault();
                                                                deleteShapes(selectedShapes);
                                                                this.layer.draw();
                                                                setValue(setField, this.stage.toJSON());
                                                            }} label="Remove" size="medium" startIcon={<DeleteIcon />} style={{ display: 'block' }} />

                                                            {/* Clone */}
                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <Button onClick={(event) => {
                                                                        event.preventDefault();
                                                                        cloneShape(selectedShapes[0]);
                                                                        this.layer.draw();
                                                                        setValue(setField, this.stage.toJSON());
                                                                    }} label="Clone" size="medium" startIcon={<CopyAllOutlined />} style={{ display: 'block' }} />
                                                                )
                                                            }

                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid #eee' }}>
                                                            {/* Change width & height of a rectangle */}
                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <React.Fragment>
                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            changeWidthHeight(selectedShapes);
                                                                            this.layer.draw();
                                                                            setValue(setField, this.stage.toJSON());
                                                                        }} label="Edit Size" size="medium" startIcon={<PhotoSizeSelectActual />} style={{ display: 'block' }} />
                                                                    </React.Fragment>
                                                                )
                                                            }

                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <Button onClick={(event) => {
                                                                        event.preventDefault();
                                                                        renameLabel(selectedShapes[0]);
                                                                        this.layer.draw();
                                                                        setValue(setField, this.stage.toJSON());
                                                                    }} label="Rename" size="medium" startIcon={<EditIcon />} style={{ display: 'block' }} />
                                                                )
                                                            }
                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid #eee' }}>
                                                            {/* Change bg color */}
                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <React.Fragment>
                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            changeBgColor(selectedShapes);
                                                                            this.layer.draw();
                                                                            setValue(setField, this.stage.toJSON());
                                                                        }} label="BG Color" size="medium" startIcon={<FormatColorFill />} style={{ display: 'block' }} />
                                                                    </React.Fragment>
                                                                )
                                                            }


                                                            {/* Change fg color */}

                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <React.Fragment>
                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            changeFgColor(selectedShapes);
                                                                            this.layer.draw();
                                                                            setValue(setField, this.stage.toJSON());
                                                                        }} label="FG Color" size="medium" startIcon={<FormatColorText />} style={{ display: 'block' }} />
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid #eee' }}>
                                                            {/* Selectable/Unselectable switch */}
                                                            {
                                                                selectedShapes.length === 1 && (
                                                                    <React.Fragment>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={selectedShapes[0].attrs.selectable}
                                                                                    onChange={(event) => {
                                                                                        // console.log(selectedShapes[0]);
                                                                                        selectedShapes[0].setAttr('selectable', event.target.checked);
                                                                                        this.layer.draw();
                                                                                        setValue(setField, this.stage.toJSON());

                                                                                        // this.setState({ selectedShapes: [] });
                                                                                        setSelectedShapes([]);
                                                                                    }}
                                                                                    name="selectable"
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label="Selectable"
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{ flexDirection: 'column' }}>

                                                        <h4>View Products:</h4>

                                                        {
                                                            selectedShapes.length > 1 ? (
                                                                "Please select only one area to view products"
                                                            ) : (
                                                                <React.Fragment>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        {
                                                                            selectedShapes[0].attrs.tickets && selectedShapes[0].attrs.tickets.length > 0 && JSON.parse(selectedShapes[0].attrs.tickets).length > 0 ? (
                                                                                JSON.parse(selectedShapes[0].attrs.tickets).map((product, index) => {

                                                                                    let ticket = null;

                                                                                    standardEventTicketGroups.forEach(group => {
                                                                                        if (!useTickets || !useTickets[group]) return;
                                                                                        const ticketFound = useTickets[group].find(t => t.id === product);
                                                                                        if (ticketFound) ticket = ticketFound;
                                                                                    });

                                                                                    if (!ticket) {
                                                                                        return null;
                                                                                    }

                                                                                    return (
                                                                                        <div key={ticket.id}>
                                                                                            {ticket.title.tr}
                                                                                            {
                                                                                                ticket.price ? (
                                                                                                    <span style={{ fontSize: 10, color: 'gray' }}> - {ticket.price}TL</span>
                                                                                                ) : null
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            ) : (
                                                                                <div>No products assigned yet.</div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }


                                                        <h4>Assign Products:</h4>
                                                        {
                                                            selectedShapes.length > 1 ? (
                                                                "Please select only one area to assign products"
                                                            ) : (
                                                                <React.Fragment>

                                                                    <FormControl variant="outlined">
                                                                        <InputLabel id="lblid1234">Products</InputLabel>
                                                                        <Select
                                                                            labelId="lblid1234"
                                                                            value={selectedAreaProducts}
                                                                            onChange={(event) => {
                                                                                // this.setState({ selectedAreaProducts: event.target.value });
                                                                                setSelectedAreaProducts(event.target.value);
                                                                            }}
                                                                            label="Product"
                                                                            style={{ minWidth: 150 }}
                                                                            multiple
                                                                        >
                                                                            {/* ticket */}
                                                                            {standardEventTicketGroups.map(ticketGroup => (
                                                                                useTickets && useTickets[ticketGroup] && (
                                                                                    useTickets[ticketGroup].map(ticket => (
                                                                                        <MenuItem key={ticket.id} value={ticket.id}>{ticket.title.tr}</MenuItem>
                                                                                    ))
                                                                                )
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>

                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            assignProductsToArea(selectedShapes);
                                                                        }} label="Assign" size="medium" startIcon={<EditIcon />} style={{ display: 'block' }} />

                                                                        <Button onClick={(event) => {
                                                                            event.preventDefault();
                                                                            selectedShapes.forEach(shape => {
                                                                                shape.setAttr('tickets', null);
                                                                                shape.children[0].fill('white');
                                                                            });
                                                                            this.layer.draw();
                                                                            setValue(setField, this.stage.toJSON());
                                                                        }} label="Remove" size="medium" startIcon={<DeleteIcon />} style={{ display: 'block' }} />

                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }

                                        </div>
                                    )}
                                </React.Fragment>
                            ) : (
                                <div>
                                    <br /><br />
                                    {isAssignMode ? `Select ${layoutType === 'seating' ? 'seats' : 'an area'} to assign a product` : `Create ${layoutType === 'seating' ? 'seats' : 'areas'} to see relevant actions`}
                                </div>
                            )
                        }
                    </div>
                )
            }

            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid black' }}>
                {
                    (!isAssignMode && !isViewOnly) && (
                        layoutType !== 'area' ? (
                            <div style={{ borderBottom: '2px solid black' }}>
                                <Button onClick={(event) => addShape(event, 'rect')} label="Add Seat" size="medium" startIcon={<AddIcon />} />
                            </div>
                        ) : (
                            <React.Fragment>
                                {
                                    layerRef.current && layerRef.current.find('Image').length === 0 ? (
                                        <Button onClick={(event) => addBackgroundImage(event)} label="Add Background (Image)" size="medium" startIcon={<AddIcon />} />
                                    ) : (
                                        <Button onClick={(event) => removeBackgroundImage(event)} label="Remove Background (Image)" size="medium" startIcon={<DeleteIcon />} />
                                    )
                                }
                                <Button onClick={(event) => addArea(event, 'rectangle')} label="Add Area (Rectangle)" size="medium" startIcon={<AddIcon />} />
                                <Button onClick={(event) => addArea(event, 'circle')} label="Add Area (Circle)" size="medium" startIcon={<AddIcon />} />
                            </React.Fragment>
                        )
                    )
                }
                <div id="scrollContainer" style={{ overflow: 'scroll', width: window.innerWidth / 1.5, height: window.innerHeight / 1.5 }}>
                    {
                        !isAssignMode ? (
                            <Stage
                                width={width} height={height}
                                ref={stageRef}
                            >
                                <Layer ref={layerRef}></Layer>
                            </Stage>
                        ) : (
                            <div id="container" style={{ width: width, height: height }}></div>
                        )
                    }
                </div>
                {layoutType !== 'area' && (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 50, backgroundColor: 'lightgrey' }}>
                        <div>STAGE</div>
                    </div>
                )}
            </div>
        </div>
    );
};

// Utils
const findIndexWithTickets = (stageJSON) => {
    // This is critical - make sure we find the index with tickets.
    let useIndex = 0;

    // Find the first index where ss.children[index].children[index].attrs.tickets is available and break the loop
    stageJSON.children.forEach((e, i) => {
        if (e.children) {
            e.children.forEach((a, j) => {
                if (a.attrs && a.attrs.tickets) {
                    useIndex = i;
                    return;
                }
            })
        }
    })

    return useIndex;
}

export default SeatingPlanCanvas;
