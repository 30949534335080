import React, { useEffect, useState } from "react";
import { usePermissions, Link, useGetOne } from "react-admin";

// Services
import { db } from "../database/firebase";
import { collection, getDocs, query, orderBy, limit, where } from "firebase/firestore";

// Libs
import { DataGridPremium, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-premium';
import moment from 'moment';

const D_LAST_X_ROWS = 100;
const D_ORDER_ON = 'createDateTime';
const D_ODER = 'desc';
const D_ENTITY = 'users';
const D_ENTITY_LABEL = 'Users';
const D_COLUMNS = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Name', width: 120, valueGetter: (params) => params.row.name.concat(' ', params.row.surname) },
    { field: 'createDateTime', headerName: 'Date/Time', width: 150, valueGetter: (params) => params.row.createDateTime ? moment(params.row.createDateTime.toDate()).format('DD/MM/YY HH:mm') : moment(params.row.createdate, 'YYYY-MM-DD').format('LL') },
    {
        field: 'gender',
        headerName: 'Gender',
        width: 50,
        valueGetter: function (params) {
            if (params.row.gender === 'male') return "M";
            else if (params.row.gender === 'female') return "F";
            else if (params.row.gender === 'prefernottosay') return "PNTS";
            else return "N/A";
        }
    },
    // mobile column
    {
        field: 'mobile',
        headerName: 'Mobile',
        width: 150,
        valueGetter: (params) => params.row.mobile ? params.row.mobile : 'N/A'
    },
    // email column
    {
        field: 'email',
        headerName: 'Email',
        width: 200,
        valueGetter: (params) => params.row.email ? params.row.email : 'N/A'
    },
    // crole column
    {
        field: 'crole',
        headerName: 'Role',
        width: 150,
        valueGetter: (params) => params.row.crole ? params.row.crole : 'User'
    },
    {
        field: 'rid',
        headerName: 'Belongs To',
        width: 120,
        renderCell: (params) => {
            let user = params.row;
            if (user.rid) {
                switch (user.crole) {
                    case "organisationCompanyManager":
                        return <DynamicCell reference={"organisationCompanies"} id={user.rid} field={"name"} />
                    case "venueManager":
                        return <DynamicCell reference="venues" id={user.rid} field="name" />
                    default:
                        return "N/A";
                }
            } else {
                return 'N/A';
            }
        }
    },
    {
        field: 'country',
        headerName: 'Country',
        width: 50,
        valueGetter: (params) => params.row.country ? params.row.country : 'N/A'
    },
    {
        field: 'verified',
        headerName: 'Verified',
        width: 50,
        valueGetter: (params) => params.row.isVerified ? 'Yes' : 'No',
        renderCell: (params) => params.row.isVerified ? (
            <span style={{ color: 'green' }}>
                {/* tick */}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.854 4.146a.5.5 0 0 0-.708-.708L7 9.293 5.854 8.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l4-4z" />
                </svg>
            </span>
        ) : (
            <span style={{ color: 'red' }}>
                {/* cross */}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm.354 4.646a.5.5 0 0 0-.708 0L8 8.293 5.354 5.646a.5.5 0 0 0-.708.708L7.293 9l-2.647 2.646a.5.5 0 0 0 .708.708L8 9.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 9l2.647-2.646a.5.5 0 0 0 0-.708z" />
                </svg>
            </span>
        )
    },
    {
        field: 'sales',
        headerName: 'Sales',
        disableExport: true,
        disablePrint: true,
        width: 100,
        sortable: false,
        renderCell: (params) => <a href={`/#/sales?userID=${params.row.id}`}>View Sales</a>
    },
    {
        field: 'edit',
        headerName: 'Edit',
        disableExport: true,
        disablePrint: true,
        width: 100,
        sortable: false,
        renderCell: (params) => {
            const onClick = () => {
                // navigate(`/users/${params.row.id}`);
                window.location.href = `/#/users/${params.row.id}`;
            };

            return <button onClick={onClick}>Edit</button>
        }
    },

]
const INITIALLY_VISIBLE_COLUMNS = {
    id: false
};

const DataPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState(D_COLUMNS);

    // Inputs
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    // Filters
    const [emailFilter, setEmailFilter] = useState('');
    const [phoneFilter, setPhoneFilter] = useState('');

    const { permissions } = usePermissions();

    const getData = async () => {

        const collectionRef = collection(db, D_ENTITY);

        let q = null

        if (emailFilter && emailFilter.length > 0 && emailFilter.indexOf('@') > -1) {
            q = query(collectionRef, where('email', '==', emailFilter));
        }
        else if (phoneFilter && phoneFilter.length > 0) {
            q = query(collectionRef, where('mobile', '==', phoneFilter));
        }
        else q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), limit(D_LAST_X_ROWS));

        const dataSnapshot = await getDocs(q);

        if (dataSnapshot) {

            let data = [];
            dataSnapshot.forEach((doc) => {
                data.push(
                    {
                        id: doc.id,
                        ...doc.data()
                    }
                );
            });

            setData(data);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, [emailFilter, phoneFilter]);

    const CustomTableHeader = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport
                    csvOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }}
                    printOptions={{
                        hideToolbar: true,
                        fields: [
                            'name', 'createDateTime', 'gender', 'mobile', 'email', 'country', 'verified'
                        ]
                    }}
                />
            </GridToolbarContainer>
        );
    }


    if (!permissions) return <PageMessage message="Loading User Permissions..." /> // loading permissions
    if (isLoading) return <PageMessage message="Loading Data..." /> //loading data

    return (
        <div>

            <div style={{ marginLeft: 20 }}>
                <h1>{D_ENTITY_LABEL} List</h1>
                <span>This list shows the last {D_LAST_X_ROWS} entries ordered on date/time, in order to view more and specific {D_ENTITY} you can query them via events page.</span>
            </div>

            <div style={{ marginLeft: 20, justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                <h3>Search By: </h3>

                <FilterRow value={email} filterValue={emailFilter} setValue={setEmail} setFilterValue={setEmailFilter} label="Email   " />
                <br />
                <FilterRow value={phone} filterValue={phoneFilter} setValue={setPhone} setFilterValue={setPhoneFilter} label="Phone" />
            </div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', margin: 0, padding: 0, width: '100%' }}>
                <div className="container" style={{ padding: 20, width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <DataGridPremium
                                rows={data}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: INITIALLY_VISIBLE_COLUMNS
                                    },
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                density="compact"
                                style={{ flex: 1, width: '100%' }}
                                getRowId={(row) => row.id}
                                columns={columns}
                                slots={{
                                    toolbar: CustomTableHeader
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DataPage;



// UTILS

const PageMessage = ({ message }) => (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', margin: 0, padding: 0, width: '100%' }}>
        <div className="container" style={{ padding: 20, width: '100%' }}>
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', height: '100%', fontSize: 14 }}>
                    {message}
                </div>
            </div>
        </div>
    </div>
)


const FilterRow = ({ value, filterValue, label, setValue, setFilterValue }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span style={{ marginRight: 10 }}>{label}:</span>

            {
                filterValue ?
                    <span style={{ marginRight: 10 }}>{filterValue}</span>
                    : (
                        <input
                            type="text"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    )
            }

            <button
                style={{ marginLeft: 10 }}
                onClick={() => setFilterValue(value)}>
                Search
            </button>

            <button
                style={{ marginLeft: 10 }}
                onClick={() => {
                    setValue('');
                    setFilterValue('');
                }}>
                Clear
            </button>


        </div>
    )
}

const DynamicCell = ({ reference, id, field, suffix }) => {
    const { data, isLoading, error } = useGetOne(
        reference,
        { id }
    );

    if (isLoading) return <span>Loading...</span>
    if (error) return <span>Error</span>

    if (field.indexOf('+') > -1) {
        let fields = field.split('+');
        return <span>{data[fields[0]]} {data[fields[1]]}</span>
    }
    else return <span>{data[field]}</span>
}