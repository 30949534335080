import * as React from "react";
import {
    Edit, SimpleForm, required, ReferenceInput, BooleanInput,
    FunctionField, TextInput, DateInput, SelectInput, NumberInput, FormDataConsumer
} from 'react-admin';

// Components
import InputRow from '../components/form/InputRow';
import NoDeleteToolbar from '../components/form/NoDeleteToolbar';

// Constants
import COUNTRIES from '../constants/countries';
import GENDERS from '../constants/genders';

const CROLES = [
    { id: 'operator', name: 'Operator' },
    { id: 'editor', name: 'Editor' },
    { id: 'promoter', name: 'Promoter', disabled: true },
    { id: 'venueManager', name: 'Venue Manager', disabled: true },
    { id: 'organisationCompanyManager', name: 'Organisation Company Manager', disabled: true },
    { id: 'admin', name: 'Admin', disabled: true }
]

// Entity
const entity = "users";

export const UserEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={`/${entity}`} toolbar={<NoDeleteToolbar />}>
            <FunctionField source="name" render={r => `${r.name} ${r.surname}`} fontSize={25} />
            <hr />
            <h3>Personal Information</h3>
            <InputRow>
                <TextInput source="name" fullWidth validate={required()} />
                <TextInput source="surname" fullWidth validate={required()} />
                <SelectInput source="gender" choices={GENDERS} fullWidth validate={required()} />
            </InputRow>
            <InputRow>
                <TextInput source="mobile" fullWidth validate={required()} />
                <TextInput source="email" fullWidth validate={required()} disabled />
            </InputRow>
            <InputRow>
                <SelectInput source="country" choices={COUNTRIES} fullWidth validate={required()} disabled />
                <ReferenceInput source="city" reference="cities" defaultValue={null}>
                    <SelectInput optionText={"name.en"} fullWidth disabled defaultValue={null} />
                </ReferenceInput>
            </InputRow>
            <InputRow>
                <DateInput source='createdate' fullWidth label="Membership Date" disabled />
                <DateInput source="dob" fullWidth validate={required()} disabled label="Date of birth" />
            </InputRow>
            <BooleanInput source="newsletter" label={"Newsletter Subscription"} fullWidth validate={required()} />
            <BooleanInput source="isVerified" label={"Verified User"} fullWidth validate={required()} />
            <NumberInput source="verificationCode" label="Verification Code" helperText="When you change this code, a new verification email will be sent to the user" />

            <h3>Role</h3>
            <SelectInput source="crole" label="Role" choices={CROLES} fullWidth defaultValue={null} />
            <FormDataConsumer>
                {({ formData }) => {
                    if (formData.crole === "organisationCompanyManager") {
                        return (
                            <ReferenceInput source={"rid"} reference="organisationCompanies">
                                <SelectInput optionText="name" disabled fullWidth />
                            </ReferenceInput>
                        );
                    }
                    if (formData.crole === "venueManager") {
                        return (
                            <ReferenceInput source="rid" reference="venues">
                                <SelectInput optionText="name" disabled fullWidth />
                            </ReferenceInput>
                        );
                    }
                    return null;
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);