import * as React from "react";
import {
    List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput,
    required, AutocompleteInput, useEditContext, 
    Toolbar, SaveButton, ReferenceInput, ReferenceField, SelectInput, FormDataConsumer, FunctionField
} from 'react-admin';

// Components
import EmptyList from "../components/misc/EmptyList";
import SeatingPlanEditor from "../components/seating/SeatingPlanEditor";

// UI
import { Box } from '@mui/material';

// Entity
const entity = "layouts";

const layoutTypes = [
    { id: 'seating', name: 'Seat Plan' },
    { id: 'area', name: 'Area Layout' },
];

export const LayoutList = (props) => (
    <List {...props} empty={<EmptyList entity={entity} />} exporter={false} perPage={50}>
        <Datagrid 
        bulkActionButtons={false}
        >
            <TextField source="name" label={"Name"} />
            <ReferenceField source="venue" reference="venues" label="Venue">
                <TextField source="name" />
            </ReferenceField>
            <FunctionField source="layoutType" render={r => {
                const layoutType = layoutTypes.find(l => l.id === r.layoutType);
                return layoutType ? layoutType.name : '';
            }} />
            <EditButton label="VIEW" icon={null} startIcon={null} endIcon={null} />
        </Datagrid>
    </List>
);

export const LayoutEdit = (props) => {
    return (
        <Edit title="Edit" {...props} actions={false}>
            <LayoutEditForm />
        </Edit>
    )
}
const LayoutEditForm = (...props) => {
    const { record } = useEditContext();

    return (
        <SimpleForm redirect={`/${entity}`} toolbar={false}>
            <TextInput source="name" label={"Name"} fullWidth />
            <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }} validate={required()}>
                <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} validate={required()} disabled />
            </ReferenceInput>
            {
                (record && record.id) ? (
                    <React.Fragment>
                        <div style={{ marginBottom: 10, color: 'red' }}>
                            Layouts can not be edited after they are created. If an alternative layout is needed for the venue please consider creating a new one.
                        </div>`
                        <SeatingPlanEditor record={record} isViewOnly={true} isAssignMode={true} />
                    </React.Fragment>
                ) : (
                    <div style={{ marginBottom: 10, color: 'red' }}>
                        There was an error loading the layout.
                    </div>
                )
            }
        </SimpleForm>
    )
};

const LayoutCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            transform={data => {
                const seatingPlan = localStorage.getItem('seatingPlan');
                if (seatingPlan) localStorage.removeItem('seatingPlan');
                return { ...data, notify: true, layout: seatingPlan ?? null };
            }}
            type="button"
        />
    </Toolbar>
);

export const LayoutCreate = (props) => {
    return (
        <Create title="New" {...props} redirect={'list'}>
            <SimpleForm redirect={'list'} toolbar={<LayoutCreateToolbar />}>
                <TextInput source="name" label={"Name"} fullWidth validate={required()} />

                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput source="layoutType" label="Type" choices={layoutTypes} fullWidth defaultValue={'seating'} validate={required()} />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }} validate={required()}>
                            <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} validate={required()} />
                        </ReferenceInput>
                    </Box>
                </Box>

                <FormDataConsumer>
                    {({ formData }) => <SeatingPlanEditor record={props.record} layoutType={formData.layoutType} isAssignMode={false} isCreateMode={true} />}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
}
