import React from "react";
import {
    List, Datagrid, TextField, EditButton, ReferenceInput, SelectInput, ReferenceField,
    FunctionField, SearchInput, usePermissions, BooleanField, AutocompleteInput, Link,
    useCreate
} from 'react-admin';

// Components
import EmptyList from "../../components/misc/EmptyList";
import ConditionalDeleteButton from "../../components/misc/ConditionalDeleteButton";

// UI
import { Grid, Typography } from '@material-ui/core';

// Libs
import moment from 'moment';

// Utils
import { truncateString } from '../../utils/misc';

// Entity
const entity = "events";


const filters = [
    <SearchInput source="name" />,
    <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} />
    </ReferenceInput>,
    <ReferenceInput source="city" reference="cities">
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>,
    <ReferenceInput source="category" reference="categories">
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>,
    <SelectInput source="isActive" choices={[{ id: true, name: 'Active' }, { id: false, name: 'Inactive' }]} label="Active Filter" />,
    <SelectInput source="isVerified" choices={[{ id: true, name: 'Verified' }, { id: false, name: 'Awaiting' }]} label="Verified Filter" />,
    <ReferenceInput source="subcategory" reference="subcategories" sort={{ field: 'name.en', order: 'ASC' }}>
        <SelectInput optionText={"name.en"} fullWidth />
    </ReferenceInput>
]


/**
 * 
 * @returns Event List
 */
export const EventList = (props) => {
    const { permissions } = usePermissions();

    if (!permissions) return;

    let isAdmin = false;
    let isOperator = false;
    let isEditor = false;

    let filter = {
        dontShowInPanel: false,
        hasSessions: !true,
        ise: false
    };

    if (permissions.crole && permissions.crole === "organisationCompanyManager" && permissions.rid) {
        filter.organisationCompanies = permissions.rid;
    }
    if (permissions.crole && permissions.crole === "venueManager" && permissions.rid) {
        filter.venue = permissions.rid;
    }

    if (permissions.crole && permissions.crole === "admin") {
        isAdmin = true;
    }

    if (permissions.crole && permissions.crole === "operator") {
        isOperator = true;
    }

    if (permissions.crole && permissions.crole === "editor") {
        isEditor = true;
    }


    if (permissions.crole && permissions.crole !== "admin" && permissions.crole !== "operator" && !permissions.rid) return <EmptyList customMessage="You don't the right permission set to view this page. Please contact GişeKıbrıs if you believe this is an error." noCreate={true} />;

    if(isEditor) {
        // redirect to news
        window.location.href = '/#/news';
    }

    if (isEditor) {
        return (
            <div style={{ flex: 1 }}>
                <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                    <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 20 }}>
                        <Typography variant="h4" gutterBottom>
                            Dashboard
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Access Denied
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You do not have permission to access this page.
                        </Typography>
                    </Grid>

                </Grid>
            </div>
        );
    }


    return (
        <List {...props} empty={<EmptyList entity={entity} />} sort={{ field: 'startdate', order: 'DESC' }} filters={filters} exporter={false} filter={filter} perPage={50}>
            <EventsDataGrid isAdmin={isAdmin} isOperator={isOperator} />
        </List>
    )
}

const EventsDataGrid = ({ isAdmin, isOperator }) => {

    return (

        <Datagrid bulkActionButtons={false}>

            <FunctionField source="name" sortable={true} sortBy="name" sortByOrder="ASC" render={r => truncateString(r.name, 30)} />

            <ReferenceField source="venue" reference="venues" link={false} sort={{ field: 'name', order: 'ASC' }}>
                <FunctionField source="name" render={r => truncateString(r.name, 20)} />
            </ReferenceField>

            <ReferenceField source="category" reference="categories" link={false}>
                <TextField source="name.en" label={"Category"} />
            </ReferenceField>

            <FunctionField source="startdate" label="Date" render={(r) => `${moment(r.startdate).format('DD/MM/YY')}`} />
            <FunctionField source="startdate" label="Time" render={(r) => `${moment(r.startdate).format('LT')}`} />

            <BooleanField source="isActive" label="Active" />

            <FunctionField source="isVerified" label="Verified" render={(r) => {
                let bgColor = 'orange';
                let status = 'No';
                if (r.isVerified) { bgColor = 'green'; status = 'Yes' }

                return (
                    <div style={{ backgroundColor: bgColor, textAlign: 'center', borderRadius: 5, padding: 3 }}>
                        <span style={{ color: 'white', textAlign: 'center' }}>{status}</span>
                    </div>
                )
            }} />

            <FunctionField source="id" label="Status" render={(r) => {
                let bgColor = 'orange';
                let status = 'Upcoming';

                if (moment(r.startdate).isBefore(moment())) { bgColor = 'grey'; status = 'Past' }

                if (r.isCancelled) {
                    bgColor = 'red';
                    status = 'Cancelled';
                }

                return (
                    <div style={{ backgroundColor: bgColor, textAlign: 'center', borderRadius: 5, padding: 3 }}>
                        <span style={{ color: 'white', textAlign: 'center' }}>{status}</span>
                    </div>
                )
            }} />

            {
                (!isOperator) && (
                    <FunctionField source="id" label="Stock Status" render={r => {
                        return !r.hasSessions ? (
                            <a href={`/#/events/${r.id}/show`}>Sales Report</a>
                        ) : (
                            "Main Session"
                        )
                    }} />
                )
            }

            {
                (!isOperator) && (
                    <FunctionField source="sales" label="Sales" render={r => <a href={`/#/sales?eventID=${r.id}`}>View Sales</a>} />
                )
            }

            {
                (!isOperator) && (
                    <FunctionField source="tickets" label="Tickets" render={r => <a href={`/#/tickets?eventID=${r.id}`}>View Tickets</a>} />
                )
            }

            <FunctionField source="id" label="" render={
                r => r.id === 'S89bNSGfhdxzYhlyASPl' ? null : <EditButton />
            } />

            <FunctionField source="id" label="" render={r =>
                <CustomCloneButton record={r} />
            } />

            {/* {
                isAdmin && (
                    <FunctionField source="id" label="" render={
                        r => <ConditionalDeleteButton record={r} />
                    } />
                )
            } */}

        </Datagrid>
    )
}


// Utils
const omitUniqueFields = (record) => {

    let tickets = {};
    // construct tickets.

    Object.keys(record.tickets).forEach((key) => {
        // deal, extras, general, guestlist, invitation, vip
        let ticketGroup = record.tickets[key];

        if (ticketGroup && ticketGroup.length > 0) {

            let ticketGroupClone = [];


            ticketGroup.forEach((ticket) => {
                let ticketClone = { ...ticket };
                ticketClone.stock = ticket.sold + ticket.stock;
                ticketClone.sold = 0;
                ticketGroupClone.push(ticketClone);
            });

            tickets[key] = ticketGroupClone;

        } else {
            tickets[key] = [];
        }
    });



    let coupons = null;
    // construct coupons.
    if (record.coupons && record.coupons.length > 0) {
        coupons = [];
        record.coupons.forEach((coupon) => {
            let couponClone = { ...coupon };

            if (couponClone.used === undefined || couponClone.used === null) {
                couponClone.used = 0;
                couponClone.stock = 0;
            } else {
                couponClone.used = 0;
            }

            couponClone.activeUntil = moment().add(60, 'day').format('YYYY-MM-DD');

            coupons.push(couponClone);
        });
    }

    // if ise != true && layout != null && layoutJSON != null then copy layout and layoutJSON
    let layout = null;
    let layoutJSON = null;
    if (!record.ise && record.layout && record.layoutJSON) {
        layout = record.layout;
        layoutJSON = record.layoutJSON;
    }

    return {
        category: record.category,
        city: record.city,
        coupons: coupons,
        description: record.description,
        details: record.details,
        layout: layout,
        layoutJSON: layoutJSON,
        name: record.name,
        organisationCompanies: record.organisationCompanies ?? [],
        paymentMethods: record.paymentMethods ?? [],
        rules: record.rules ?? {},
        subcategories: record.subcategories ?? [],
        tickets: tickets,
        venue: record.venue,
        venueLayout: null,
        videoURL: record.videoURL ?? null, // '' ?
        isCommissionExtra: record.isCommissionExtra ? record.isCommissionExtra : false,
        commissionFee: record.commissionFee ? record.commissionFee : 0,
        servicefee: record.servicefee ? record.servicefee : 0,
        isCloned: true,


        // other computed fields
        startdate: moment().add(1, 'days').toDate(),
        enddate: moment().add(2, 'days').toDate(),
        isActive: false,
        isVerified: false,
        isCancelled: false,

        banner: null,
        createdate: moment().toDate(),
        createdby: 'dev@nokosoft.com',
        lastupdate: moment().toDate(),
        updatedby: 'dev@nokosoft.com',
        hasSentNotification: false,
        notify: true,
    }

};
const CustomCloneButton = ({ record }) => {
    const [create, { data, isLoading, error }] = useCreate('events', { data: omitUniqueFields(record) });

    const handleClick = () => {
        window.confirm('Are you sure you want to clone this event? Please pay attention to the dates as they will be dummy in the cloned event.') && create();
    }

    if (error) {
        alert('Error cloning event. Please try again, if the problem persists contact support.');
    }

    if (data) {
        window.location.href = `/#/events-v2/${data.id}`;
    }

    return <Link disabled={isLoading} onClick={handleClick}>CLONE</Link>;


};