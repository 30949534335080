import React, { useEffect, useState } from "react";
import { Show, useShowContext, useNotify, usePermissions, Button, Link } from "react-admin";

// Libs
import moment from "moment";

// Firebase
import { db } from "../../database/firebase";
import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc } from "firebase/firestore";

// UI
import { ContentDuplicate, FileDocument, PointOfSale, Ticket, FileEdit, CreditCard, Calendar, Cancel } from "mdi-material-ui";

// Components
import EmptyList from "../../components/misc/EmptyList";
import ConditionalDeleteButton from "../../components/misc/ConditionalDeleteButton";

import EmptyStateImage from '../../assets/empty-state.png';

const EventStockReport = () => {

    let hasEnteredFetching = false;

    const { record } = useShowContext();
    const event = record;

    const notify = useNotify();
    const { permissions } = usePermissions();

    const [isLoading, setIsLoading] = useState(true);

    const [eventStatsData, setEventStatsData] = useState({
        totalRevenue: 0,
        totalSales: 0,
        totalTickets: 0,
        totalSeats: 0,
        totalRemaining: 0,
        totalCouponDiscount: 0,
        totalCommssionAmount: 0,
        totalAmountOfCashSales: 0,
    });

    // stats and payment
    const [isSyncingSaleStats, setIsSyncingSaleStats] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [isPaid, setIsPaid] = useState(false);

    let isAdmin = false;
    if (permissions.crole && permissions.crole === "admin") isAdmin = true;

    useEffect(() => {

        (
            async () => {
                if (!event || !event.id) return;
                await fetchEventSalesData();
                return;
            }
        )()

    }, [event]);


    const cancelEvent = async () => {
        if (window.confirm("Are you sure you want to cancel this event?")) {
            await updateDoc(doc(db, "events-v2", event.id), {
                isCancelled: true,
                isActive: false,
                isVerified: false,
            }).then(() => {
                // notify('Event has been cancelled.', {
                //     type: 'success'
                // });
                // redirect to events list
                window.location.href = '/#/events-v2';
            }).catch((error) => {
                notify('Error cancelling the event, please contact Gişe Kıbrıs.', { type: 'error' });
            });
        }
    }

    const fetchEventSalesData = async () => {

        if (hasEnteredFetching) return;
        hasEnteredFetching = true;

        // fetch sales data /eventStats
        const q = query(
            collection(db, "eventStats"),
            where("event", "==", event.id)
        );
        const querySnapshot = await getDocs(q);

        let eventStatsDataTemp = {};
        querySnapshot.forEach((doc) => {
            eventStatsDataTemp = {
                totalRevenue: doc.data().totalRevenue ?? 0,
                totalSales: doc.data().totalSales ?? 0,
                totalTickets: doc.data().totalTickets ?? 0,
                totalSeats: doc.data().totalSeats ?? 0,
                totalRemaining: doc.data().totalRemaining ?? 0,
                totalCouponDiscount: doc.data().totalCouponDiscount ?? 0,
                totalCommssionAmount: doc.data().totalCommssionAmount ?? 0,
                totalAmountOfCashSales: doc.data().totalAmountOfCashSales ?? 0,
            }
        });

        if (eventStatsDataTemp && Object.keys(eventStatsDataTemp).length > 0) setEventStatsData(eventStatsDataTemp);

        setIsLoading(false);
    }

    const onPressMakePayment = () => {
        // we will set the field isPaid to true for the "event" object.

        if (event.isPaid === true) {
            alert(`Event payment has already been made, with the following note (if any): ${event.payNote}`);
            return;
        }

        let confirmMSG = `Current status of the payment is: ${isApproved ? 'APPROVED' : 'NOT APPROVED'}. Do you want to make the payment? You can also write an optional note.`;
        let confirmMSGNote = window.prompt(confirmMSG);
        if (confirmMSGNote || confirmMSGNote === '') {
            updateDoc(doc(db, "events-v2", event.id), {
                isPaid: true,
                payNote: confirmMSGNote,
            }).then(() => {
                setIsPaid(true);
                notify('Event payment has been made.', {
                    type: 'success'
                });
            }).catch((error) => {
                // console.error('Error making payment for event: ', error);
                notify('Error making payment for the event, please contact Gişe Kıbrıs.', {
                    type: 'error'
                });
            });
        }
    }

    if (isLoading || !event) return <EmptyList mode={'loading'} />

    // if (!eventStatsData) return <EmptyList customMessage="No sales data found for this event." noCreate={true} />

    const hasEventPassed = moment().isAfter(moment(event.enddate));
    const isEventActive = moment().isAfter(moment(event.startdate)) && moment().isBefore(moment(event.enddate));
    const isEventUpcoming = moment().isBefore(moment(event.startdate));

    return (
        <div style={{ paddingLeft: 30, paddingRight: 30, fontSize: 16 }}>

            {/* Poster */}
            <div style={{ marginBottom: 50 }}>
                <img src={
                    (
                        event.banner && event.banner.src ? event.banner.src : EmptyStateImage
                    )
                } alt={event.name} style={{ width: 300, height: 300, objectFit: 'cover', borderRadius: 5 }} />
            </div>

            <h2>
                {event.name} - {moment(event.startdate).format("LL")} /
                <span style={{ fontSize: 18, color: hasEventPassed ? 'grey' : (record.isCancelled ? 'red' : 'green') }}>
                    {
                        hasEventPassed ? ' (Passed Event)' : (record.isCancelled ? ' (Cancelled Event)' : (isEventUpcoming ? ' (Upcoming Event)' : ' (HAPPENING NOW)'))
                    }
                </span>
            </h2>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', borderBottom: '2px solid #EEE' }}>

                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '2px solid #EEE' }}>

                    {/* DETAILS */}
                    <div style={{ borderRight: '2px solid #EEE', paddingRight: 30, marginRight: 30, minWidth: 550, paddingBottom: 20 }}>

                        <h2>Details</h2>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <div><strong>Start:</strong></div>
                                <div>{moment(event.startdate).format('DD/MM/YY')} - {moment(event.startdate).format('LT')}</div>

                                <div style={{ marginTop: 10 }}><strong>End:</strong></div>
                                <div>{moment(event.enddate).format('DD/MM/YY')} - {moment(event.enddate).format('LT')}</div>

                            </div>

                            {
                                (
                                    event.dateType === 'session' && (
                                        <div style={{ marginBottom: 10 }}>
                                            <div><strong>Sessions:</strong></div>
                                            <div>{printEventSessionsLength(event.sessions)} sessions ({event.duration} minutes each)</div>
                                        </div>
                                    )
                                )
                            }

                            {
                                !hasEventPassed && (
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                        <div><strong>Verified:</strong></div>
                                        <div>{event.isVerified ? '✅ Yes - Showing up on the website and mobile application.' : '❌ No - Awaiting to be verified to be shown on the website and the mobile application.'}</div>
                                        <div><strong>Active:</strong></div>
                                        <div>{event.isActive ? '✅ Yes - Event is live and can be purchased.' : '❌ No - Event is not live and cannot be purchased.'}</div>
                                    </div>
                                )
                            }

                            {
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                    <div><strong>Event Link (Preview):</strong></div>
                                    <a href={"https://www.gisekibris.com/etkinlikler/".concat(record.id).toString().concat('?mode=preview')} target="_blank">
                                        {
                                            record.name
                                        }
                                    </a>

                                </div>
                            }
                        </div>

                    </div>

                    {/* SUMMARY SECTION  */}
                    <div>

                        <h2>Sales Summary</h2>

                        {
                            !event.transformedFromV1 ? (
                                <div style={{ width: 500, fontSize: 16 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>Total Product Sales</div>
                                        <div>{eventStatsData.totalRevenue.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>Total Sales / Tickets</div>
                                        <div>{eventStatsData.totalSales.toString().concat(' / ', eventStatsData.totalTickets.toString())}</div>
                                    </div>

                                    {
                                        event.type === 3 && (
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div>Total Seats</div>
                                                <div>{eventStatsData.totalSeats}</div>
                                            </div>
                                        )
                                    }

                                    {/* 
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Total Remaining Amount</div>
                                <div>{eventStatsData.totalRemaining.toLocaleString('tr-TR', { style: 'currency', 'currency': 'TRY' })}</div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Total Coupon Discount</div>
                                <div>{eventStatsData.totalCouponDiscount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Organisator Sales (Cash)</div>
                                <div>{eventStatsData.totalAmountOfCashSales.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>Sub Total</div>
                                <div><b>{(0).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</b></div>
                            </div> */}

                                </div>
                            ) : (
                                <span>
                                    Please note that the sales summary is not available for the transformed events,<br /> please check sales report for more details.
                                </span>
                            )
                        }


                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 30,
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                display: 'none'
                            }}>
                            {/* Sync button */}
                            <Button variant="outlined" size={"large"} label={isSyncingSaleStats ? "Loading..." : "Sync Sales"} startIcon={<FileDocument />}
                                onClick={() => {
                                    setIsSyncingSaleStats(true);
                                    // request to https://us-central1-gisekibris-8b11d.cloudfunctions.net/syncSaleStatsForEvent?eventID=record.id
                                    fetch(`https://us-central1-gisekibris-8b11d.cloudfunctions.net/syncSaleStatsForEvent?eventID=${record.id}`)
                                        .then(response => response.json())
                                        .then(data => {
                                            notify('Sales data has been synced, refreshing in 2 seconds...', {
                                                type: 'success'
                                            });
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 2000);
                                        })
                                        .catch((error) => {
                                            console.error('Error syncing sales data for event: ', error);
                                            notify('Error syncing sales data, please contact Gişe Kıbrıs.', {
                                                type: 'error'
                                            });
                                        });
                                }}
                            />
                        </div>

                    </div>

                </div>

                {/* REPORTS & ACTIONS */}
                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '2px solid #EEE', paddingBottom: 10 }}>

                    <div style={{ marginRight: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>

                        <h2>Sales & Reports</h2>
                        <div style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'middle', alignItems: 'center' }}>
                            {

                                record && record.dateType == 'oneoff' && (
                                    <div style={{ marginRight: 10 }}>
                                        <Link to={`/sale-report?eventID=${record.id}`} style={{ textDecoration: 'none' }}>
                                            <Button variant="outlined" size={"large"} label="Sale Report" startIcon={<FileEdit />}>
                                                <FileEdit />&nbsp;<span style={{ fontSize: 12 }}>Sale Report</span>
                                            </Button>
                                        </Link>
                                    </div>
                                )
                            }

                            <Button style={{ marginRight: 10 }} variant="outlined" size="large" label="View All Sales" startIcon={<PointOfSale />} href={`/#/sales?eventID=${event.id}`}>
                                <PointOfSale />&nbsp;<span style={{ fontSize: 12 }}>View All Sales</span>
                            </Button>
                            <Button variant="outlined" size="large" label="View All Tickets" startIcon={<Ticket />} href={`/#/tickets?eventID=${event.id}`}>
                                <Ticket />&nbsp;<span style={{ fontSize: 12 }}>View All Tickets</span>
                            </Button>
                        </div>

                    </div>

                </div>

                <div style={{ paddingTop: 10, paddingBottom: 10 }}>

                    <h2>Actions</h2>

                    <div style={{ display: 'flex' }}>
                        {
                            record && (
                                record.dateType === 'session' ? (
                                    <Link to={`/stocks?eventID=${event.id}`} style={{ textDecoration: 'none', marginRight: 10 }}>
                                        <Button variant="outlined" size={"large"} label="View Sessions" startIcon={<Calendar />}>
                                            <Calendar />&nbsp;<span style={{ fontSize: 12 }}>View Sessions</span>
                                        </Button>
                                    </Link>
                                ) : (

                                    <Link to={hasEventPassed ? '#' : `/stocks/${record.sessions[0].times[0].id}`}>
                                        <Button variant="outlined" size={"large"}
                                            label={`Edit ${event.type === 3 ? `Products ${hasEventPassed ? '(Passed Event)' : ''}` : `Stock ${hasEventPassed ? '(Passed Event)' : ''}`}`}
                                            startIcon={<Calendar />} disabled={hasEventPassed}
                                            style={{ marginRight: 10 }}
                                        >
                                            <Calendar />&nbsp;<span style={{ fontSize: 12 }}>Edit {event.type === 3 ? `Products ${hasEventPassed ? '(Passed Event)' : ''}` : `Stock ${hasEventPassed ? '(Passed Event)' : ''}`}</span>
                                        </Button>
                                    </Link>
                                )
                            )
                        }

                        {
                            (!hasEventPassed || isAdmin) && (
                                <div style={{ marginRight: 10 }}>
                                    <Link to={`/events-v2/${record.id}`} style={{ textDecoration: 'none' }}>
                                        <Button variant="outlined" size={"large"} label="Edit Event" startIcon={<FileEdit />}>
                                            <FileEdit />&nbsp;<span style={{ fontSize: 12 }}>Edit Event</span>
                                        </Button>
                                    </Link>
                                </div>
                            )
                        }

                        <div style={{ marginRight: 10 }}>
                            <CustomCloneButton record={record} />
                        </div>

                        {isAdmin && (
                            <Button onClick={onPressMakePayment} label={`Make Payment ${event.isPaid ? '(Paid)' : ''}`} variant={"outlined"} size={"large"} startIcon={<CreditCard />} style={{
                                // disabled style
                                backgroundColor: event.isPaid ? '#EEE' : 'unset',
                                color: event.isPaid ? '#666' : '#000'
                            }}>
                                <CreditCard />&nbsp;<span style={{ fontSize: 12 }}>Make Payment {event.isPaid ? '(Paid)' : ''}</span>
                            </Button>
                        )}


                        {(isAdmin && !record.isCancelled) &&
                            (
                                <Button variant="outlined" size={"large"} color="primary" startIcon={<Cancel />} onClick={() => cancelEvent()} label="Cancel Event" style={{ marginLeft: 10 }}>
                                    <Cancel />&nbsp;<span style={{ fontSize: 12 }}>Cancel Event</span>
                                </Button>
                            )}

                        {isAdmin &&
                            (
                                <div style={{ marginLeft: 10 }}>
                                    <ConditionalDeleteButton record={event} />
                                </div>
                            )
                        }


                    </div>

                </div>

            </div>

        </div>
    )
}


const EventV2Show = (props) => {
    return (
        <Show {...props}>
            <EventStockReport {...props} />;
        </Show>
    )
}

const omitUniqueFields = async (record) => {

    // get one of the stocks from /stocks/event:record.sessions[0].times[0].id
    const stockID = record.sessions[0].times[0].id;

    // get /stocks/stockID
    const stockDoc = doc(db, 'stocks', stockID);
    const stockDocSnap = await getDoc(stockDoc);
    let stockData = stockDocSnap.data();

    // construct tickets.

    // lets see if the event is seated === 3 or standart
    let eventType = record.type;
    let tickets = null;

    if (eventType === 3) {

        tickets = [];

        // in stockData.stock (array) they each have ID's they have to change with genereateTicketID()
        // and the rest should be equal to record.tickets
        if (stockData && stockData.stock && stockData.stock.length > 0) {
            stockData.stock.forEach((ticket) => {
                let ticketClone = { ...ticket };
                ticketClone.id = genereateTicketID();
                tickets.push(ticketClone);
            });
            record.tickets = tickets;
        }

        record.sessions = [];
    }
    else {
        tickets = {};

        Object.keys(stockData.stock).forEach((key) => {

            // deal, extras, general, guestlist, invitation, vip
            let ticketGroup = stockData.stock[key];

            if (ticketGroup && ticketGroup.length > 0) {

                let ticketGroupClone = [];

                ticketGroup.forEach((ticket) => {
                    let ticketClone = { ...ticket };
                    ticketClone.stock = ticket.stock ? ticket.stock : 0;
                    ticketClone.sold = 0;
                    ticketGroupClone.push(ticketClone);
                });

                tickets[key] = ticketGroupClone;

            } else {
                tickets[key] = [];
            }
        });

        record.tickets = tickets;
    }

    let coupons = null;
    // construct coupons.
    if (record.coupons && record.coupons.length > 0) {
        coupons = [];
        record.coupons.forEach((coupon) => {
            let couponClone = { ...coupon };

            if (couponClone.used === undefined || couponClone.used === null) {
                couponClone.used = 0;
                couponClone.stock = 0;
            } else {
                couponClone.used = 0;
            }

            // couponClone.activeUntil = moment().add(60, 'day').format('YYYY-MM-DD');
            couponClone.activeUntil = null;

            coupons.push(couponClone);
        });
    }

    // if ise != true && layout != null && layoutJSON != null then copy layout and layoutJSON
    let layoutID = null;
    let layoutJSONID = null;
    if (record.layoutID && record.layoutJSONID) {
        layoutID = record.layoutID;
        layoutJSONID = record.layoutJSONID;
    }

    // 

    return {
        category: record.category,
        city: record.city,
        coupons: coupons,
        description: record.description,
        details: record.details,
        layoutID: layoutID,
        layoutJSONID: layoutJSONID,
        name: record.name,
        organisationCompanies: record.organisationCompanies ?? [],
        paymentMethods: record.paymentMethods ?? [],
        rules: record.rules ?? {},
        subcategories: record.subcategories ?? [],
        tickets: tickets,
        venue: record.venue,
        venueLayout: null,
        videoURL: record.videoURL ?? null, // '' ?
        isCommissionExtra: record.isCommissionExtra ? record.isCommissionExtra : false,
        commissionFee: record.commissionFee ? record.commissionFee : 0,
        servicefee: record.servicefee ? record.servicefee : 0,
        isCloned: true,

        // v2
        dateType: record.dateType,
        duration: record.duration,
        type: record.type,

        // other computed fields
        startdate: null,
        enddate: null,
        isActive: false,
        isVerified: false,
        isCancelled: false,

        banner: null,
        createdate: moment().toDate(),
        createdby: 'dev@nokosoft.com',
        lastupdate: moment().toDate(),
        updatedby: 'dev@nokosoft.com',
        hasSentNotification: false,
        notify: true
    }

};

const CustomCloneButton = ({ record }) => {
    const handleClick = async () => {
        if (window.confirm('Are you sure you want to clone this event? Please pay attention to the dates as they will be empty or dummy in the cloned event.')) {
            let clonedData = await omitUniqueFields(record);
            try {
                // redirect to create with URL params
                let source = encodeURIComponent(JSON.stringify(clonedData));
                window.location.href = `/#/events-v2/create?source=${source}`;

            } catch (error) {

            }
        }
    }

    return (
        <Link onClick={handleClick}>
            <Button variant="outlined" size={"large"} label="Clone Event" startIcon={<ContentDuplicate />}>
                <ContentDuplicate />&nbsp;<span style={{ fontSize: 12 }}>Clone Event</span>
            </Button>
        </Link>
    )

};

const genereateTicketID = () => {
    // Capital letters and numbers, length 8
    return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substring(0, 8).toUpperCase();
}


const printEventSessionsLength = (sessions) => {
    // each array entry under sessions have a times array too, each count as a session.
    let totalSessions = 0;
    sessions.forEach((session) => {
        totalSessions += session.times.length;
    });
    return totalSessions;
}

export default EventV2Show;