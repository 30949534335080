import React, { useEffect, useState } from "react";
import { usePermissions, Link, useGetOne, Button, useDelete } from "react-admin";
import { useSearchParams } from 'react-router-dom'

// Services
import { db } from "../database/firebase";
import { collection, getDocs, query, orderBy, limit, where } from "firebase/firestore";

// UI
import { Grid, Typography } from '@material-ui/core';

// Libs
import { DataGridPremium, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-premium';
import moment from 'moment';

// Icons
import { Refresh, DeleteForever } from '@mui/icons-material';

// Components
import EmptyList from "../components/misc/EmptyList";
import { NewRegistrationSale } from "../components/cells/NewRegistrationSale";

const D_LAST_X_ROWS = 100;
const D_ORDER_ON = 'createdate';
const D_ODER = 'desc';
const D_ENTITY = 'sales';
const D_ENTITY_LABEL = 'Sales';
const D_COLUMNS = [
    { field: 'id', headerName: 'ID', width: 100, filterable: false },
    { field: 'user.name', headerName: 'User', width: 120, valueGetter: (params) => params.row.user.name.concat(' ', params.row.user.surname) },
    { field: 'createdate', filterable: false, headerName: 'Sale Date', width: 150, valueGetter: (params) => moment(params.row.createdate, 'x').format('DD/MM/YY HH:mm:ss') },
    {
        field: 'event.name', headerName: 'Event Name', width: 250, valueGetter: (params) => {
            return params.row.event.name; // // TODO: add date into this. Will probably need to fetch.
        }, renderCell: (params) => {
            // return <Link to={`/sales?eventID=${params.row.event.id}`}></Link>
            return params.row.event.name;
        }
    },
    {
        field: 'userMobile', headerName: 'User Mobile', width: 150, filterable: false, renderCell: (params) => {
            if (params.row.user.mobile) return params.row.user.mobile;
            // else return "-";
            else return <DynamicCell id={params.row.user.id} reference="users" field="mobile" />
        }, valueGetter: (params) => {
            if (params.row.user.mobile) return params.row.user.mobile;
            else return "-";
        }
    },
    // {
    //     field: 'venue',
    //     headerName: 'Venue',
    //     width: 200,
    //     valueGetter: (params) => {
    //         if (params.row.event.venue) return params.row.event.venue.name;
    //         else return "N/A"
    //     }
    // },
    {
        field: 'channel',
        headerName: 'Channel',
        width: 120,
        valueGetter: (params) => {
            switch (params.row.channel) {
                case "w":
                    return "Website";
                case "m":
                    return "Mobile";
                case "sp":
                    return "Sale Point"
                case "sa":
                    return "Sale Agent";
                case "op":
                    return "Org C. Promoter"
                case "vp":
                    return "Venue Promoter";
                case "a":
                    return "Affiliate";
                case "ocm":
                    return "Org C. Manager";
                case "vm":
                    return "Venue Manager";
                default:
                    return "N/A"
            }
        },
        type: 'singleSelect',
        valueOptions: [
            'Website',
            'Mobile',
            'Sale Point',
            'Sale Agent',
            'Org C. Promoter',
            'Venue Promoter',
            'Affiliate',
            'Org C. Manager',
            'Venue Manager'
        ]
    },
    {
        field: 'soldBy', headerName: 'Sold By', width: 120, filterable: false, renderCell: (params) => {
            let record = params.row;
            switch (record.channel) {
                case "w":
                    return "-";
                case "m":
                    return "-";
                case "sp":
                    return <DynamicCell id={record.saleChannelID} reference="salePoints" field="name" />
                case "sa":
                case "op":
                case "vp":
                case "ocm":
                case "vm":
                    return <DynamicCell id={record.saleChannelID} reference="users" field="name+surname" />
                case "a":
                    if (record.saleChannelID && typeof record.saleChannelID === 'string') {
                        return <DynamicCell id={record.saleChannelID} reference="affiliates" field="name" />
                    } else {
                        return <DynamicCell id={record.saleChannelID.code} reference="affiliates" field="name" />
                    }
                default:
                    return "N/A"
            }
        }, valueGetter: (params) => {
            let record = params.row;
            switch (record.channel) {
                case "w":
                    return "-";
                case "m":
                    return "-";
                case "sp":
                    return "Sale Point"
                case "sa":
                case "op":
                case "vp":
                case "ocm":
                case "vm":
                    return "User"
                case "a":
                    if (record.saleChannelID && typeof record.saleChannelID === 'string') {
                        return record.saleChannelID;
                    } else {
                        return record.saleChannelID.code;
                    }
                default:
                    return "N/A"
            }
        }

    },
    {
        field: 'payment', headerName: 'Payment (₺)', filterable: false, width: 100, renderCell: (params) => {
            let r = params.row;

            if (r.isInvitation) return "Invitation";
            if (r.isReservation) return "Reservation";

            if (params.row.paymentType && (params.row.paymentTotal || params.row.paymentTotal == 0)) {
                return r.payment && r.payment.id ? (
                    <Link to={`/payments/${r.payment.id}/show`}>
                        {`${r.paymentTotal} TL`}
                    </Link>
                ) : `${r.paymentTotal} TL`;
            }
            else if (params.row.payment && params.row.payment.id) return (
                <Link to={`/payments/${r.payment.id}/show`}>
                    <DynamicCell id={r.payment.id} reference="payments" field="total" suffix=" TL" />
                </Link>
            )
            else return "N/A";
        }, valueGetter: (params) => {
            let r = params.row;
            if (r.paymentType && r.paymentTotal) return r.paymentTotal;
            else if (r.payment && r.payment.id) return <DynamicCell id={r.payment.id} reference="payments" field="total" suffix=" TL" />
            else return "";
        }
    },
    {
        field: 'paymentType', headerName: 'Payment Type', filterable: false, width: 100, renderCell: (params) => {
            let r = params.row;
            if (params.row.paymentType) {
                return r.payment && r.payment.id ? (
                    <Link to={`/payments/${r.payment.id}/show`}>
                        {`${r.paymentType === 'cc' ? (r.paymentTotal && parseInt(r.paymentTotal) > 0 ? 'Credit Card' : 'N/A') : 'Cash'}`}
                    </Link>
                ) : `${r.paymentType === 'cc' ? (r.paymentTotal && parseInt(r.paymentTotal) > 0 ? 'Credit Card' : 'N/A') : 'Cash'}`;
            }
            else return "N/A";
        }, valueGetter: (params) => {
            let r = params.row;
            if (r.paymentType) return r.paymentType === 'cc' ? (r.paymentTotal && parseInt(r.paymentTotal) > 0 ? 'Credit Card' : 'N/A') : 'Cash';
            else return "N/A";
        }
    },
    {
        field: 'oid', headerName: 'Order ID', filterable: false, width: 75, valueGetter: (params) => {
            let r = params.row;
            if (r.oid) return r.oid;
            else return "-";
        }
    },
    {
        field: 'productsCount', headerName: '# Products', filterable: false, width: 75, valueGetter: (params) => {
            let r = params.row;
            if (r.seats && r.seats.length > 0) return r.seats.length;
            else if (r.tickets && r.tickets.length > 0) return r.tickets.length.toString().concat(' tickets');
            else return "-";
        }
    },
    {
        field: 'product', headerName: 'Product', width: 250, valueGetter: (params) => {
            let r = params.row;
            if (r.seats && r.seats.length > 0) {
                let seats = r.seats.map((seat) => seat.id);
                return seats.join(', ');
            }
            else if (r.product && r.product.title && r.product.title.en) {
                return r.product.title.en;
            }
            else return "N/A";
        }
    },
    {
        field: 'coupon', headerName: 'Coupon', width: 75, valueGetter: (params) => {
            let r = params.row;
            if (r.coupon && r.coupon.code) return r.coupon.code;
            else return "-";
        }
    },
    {
        field: 'status', headerName: 'Status', width: 100, renderCell: (params) => {
            let r = params.row;
            let bgColor = 'orange';
            let status = 'Awaiting';
            if (r.status === 1) {
                bgColor = 'green';
                status = 'Confirmed';
            }
            if (r.status === 2) {
                bgColor = 'red';
                status = 'Failed';
            }

            let toReturn = r.payment && r.payment.id ? (
                <Link to={`/payments/${r.payment.id}/show`}>
                    <div style={{ backgroundColor: bgColor, textAlign: 'center', borderRadius: 5, padding: 3, minWidth: 75 }}>
                        <span style={{ color: 'white', textAlign: 'center' }}>{status}</span>
                    </div>
                </Link>
            ) : (
                <div style={{ backgroundColor: bgColor, textAlign: 'center', borderRadius: 5, padding: 3, minWidth: 75 }}>
                    <span style={{ color: 'white', textAlign: 'center' }}>{status}</span>
                </div>
            )

            return toReturn;
        },
        valueGetter: (params) => {
            let r = params.row;
            let status = 'Awaiting';
            if (r.status === 1) status = 'Confirmed';
            if (r.status === 2) status = 'Failed';
            return status;
        },
        type: 'singleSelect',
        valueOptions: [
            'Awaiting',
            'Confirmed',
            'Failed'
        ]
    },
    {
        field: 'isNewRegistrationSale', headerName: 'New Registration (Last 7 Days)', width: 100, renderCell: (params) => <NewRegistrationSale sale={params.row} />
    },
    {
        field: 'deleteButton',
        headerName: 'Delete',
        width: 100,
        filterable: false,
        renderCell: (params) => <DeleteButton record={params.row} confirm={true} resource={'sales'} />
    }
]
const INITIALLY_VISIBLE_COLUMNS = {
    id: false, coupon: false, payment: true, product: false, deleteButton: false, soldBy: false
    // userMobile: false
};

const DataPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams()
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState(D_COLUMNS);
    const { permissions } = usePermissions();

    // Get eventID from query params
    const eventID = searchParams.get('eventID');
    const userID = searchParams.get('userID');
    const stockID = searchParams.get('stockID');

    let isManager = false;
    let iOperator = false;
    let isEditor = false;
    if (permissions.crole && permissions.crole === "organisationCompanyManager" && permissions.rid) isManager = true;
    if (permissions.crole && permissions.crole === "venueManager" && permissions.rid) isManager = true;
    if (permissions.crole && permissions.crole === "operator") iOperator = true;
    if (permissions.crole && permissions.crole === "editor") isEditor = true;

    const getData = async ({ userID, eventID, stockID }) => {

        setIsLoading(true);

        const collectionRef = collection(db, D_ENTITY);
        let q = null;
        if (eventID && !stockID) q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), where('event.id', '==', eventID));
        else if (eventID && stockID) q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), where('event.stockID', '==', stockID));
        else if (userID) q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), where('user.id', '==', userID));
        else q = query(collectionRef, orderBy(D_ORDER_ON, D_ODER), limit(D_LAST_X_ROWS));

        const dataSnapshot = await getDocs(q);

        if (dataSnapshot) {

            let data = [];


            // for (const d of dataSnapshot.docs) {
            //     let docData = d.data();

            //     if (docData.user && !docData.user.mobile) {
            //         const userSnapshot = await getDoc(doc(db, 'users', docData.user.id));
            //         if (userSnapshot) {

            //             let user = userSnapshot.data();
            //             if (user && user.mobile) docData.user.mobile = user.mobile;
            //             data.push({ id: d.id, ...docData });

            //         } else {
            //             data.push({ id: d.id, ...docData });
            //         }
            //     }
            // }

            dataSnapshot.forEach((d) => {
                let docData = d.data();
                if (eventID || userID) data.push({ id: d.id, ...docData });
                else if (docData.status && docData.status !== 0) data.push({ id: d.id, ...docData });

            });

            setData(data);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (isManager && !eventID) return window.location.href = '/#/events-v2';

        if (isManager) {
            // remove columns soldBy, channel, and deleteButton
            setColumns(columns.filter((column) => column.field !== 'soldBy' && column.field !== 'channel' && column.field !== 'deleteButton'));
        }

        getData({ userID, eventID, stockID });

    }, [searchParams]);


    if (iOperator) {
        // redirect to events
        return window.location.href = '/#/events-v2';
    }

    if (isEditor) {
        // redirect to news
        window.location.href = '/#/news';
    }


    if (!permissions) return <EmptyList mode="loading" />
    if (isLoading) return <EmptyList mode="loading" />

    if (permissions.crole && permissions.crole !== "admin" && !permissions.rid) return <EmptyList customMessage="You don't the right permission set to view this page. Please contact GişeKıbrıs if you believe this is an error." noCreate={true} />;


    const CustomTableHeader = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{ disableToolbarButton: true }} printOptions={{ hideToolbar: true }} excelOptions={{ fileName: `gisekibris-sales-${moment().format('LL-LT')}.xlsx` }} />
                <RefreshButton refreshData={() => getData({ userID, eventID, stockID })} />
            </GridToolbarContainer>
        );
    }

    if (iOperator || isEditor) {
        return (
            <div style={{ flex: 1 }}>
                <Grid container spacing={0} style={{ background: '#FFF', padding: 30 }}>
                    <Grid item xs={12} style={{ borderBottom: '1px solid #AAA', marginBottom: 20 }}>
                        <Typography variant="h4" gutterBottom>
                            Sales
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Access Denied
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You do not have permission to access this page.
                        </Typography>
                    </Grid>

                </Grid>
            </div>
        );
    }

    return (
        <div>
            {
                eventID ? (
                    eventID && stockID ? (
                        <div style={{ marginLeft: 20 }}>
                            <h1>Event Session / Sales List</h1>
                            <span>This list shows all sales for the selected event session.</span>
                        </div>
                    )
                        : (
                            <div style={{ marginLeft: 20 }}>
                                <h1>Event / Sales List</h1>
                                <span>This list shows all sales for the selected event.</span>
                            </div>
                        )
                ) : (

                    userID ? (
                        <div style={{ marginLeft: 20 }}>
                            <h1>User / Sales List</h1>
                            <span>This list shows all sales for the selected user.</span>
                        </div>
                    ) : (
                        <div style={{ marginLeft: 20 }}>
                            <h1>{D_ENTITY_LABEL} List</h1>
                            <span>This list shows the last {D_LAST_X_ROWS} entries ordered on date/time, in order to view more and specific {D_ENTITY} you can query them via events page.</span>
                        </div>
                    )
                )
            }

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', margin: 0, padding: 0, width: '100%' }}>
                <div className="container" style={{ padding: 20, width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <DataGridPremium
                                rows={data}
                                density="compact"
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: INITIALLY_VISIBLE_COLUMNS
                                    },
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                onColumnVisibilityModelChange={(model) => {
                                    setColumns(
                                        columns.map((column) => ({
                                            ...column,
                                            hide: !model[column.field],
                                        }))
                                    );
                                }}


                                style={{ flex: 1, width: '100%', minHeight: '600px' }}
                                loading={isLoading}
                                slotProps={{
                                    loadingOverlay: {
                                        variant: 'linear-progress',
                                        noRowsVariant: 'skeleton',
                                    }
                                }}
                                localeText={{ noRowsLabel: "No sales found matching the criteria." }}


                                getRowId={(row) => row.id}
                                columns={columns}
                                slots={{
                                    toolbar: CustomTableHeader,
                                    // noRowsOverlay: NoRowsSlot
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default DataPage;



// UTILS

const RefreshButton = ({ refreshData }) => {
    return (
        <button
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'black',
                cursor: 'pointer',
                fontSize: 10,
                fontWeight: 500,
                outline: 'none',
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}
            onClick={refreshData}
        >
            {/* refresh icon */}
            <Refresh style={{ fontSize: 16, marginRight: 5 }} />
            REFRESH
        </button>
    );
};

const PageMessage = ({ message }) => (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', margin: 0, padding: 0, width: '100%' }}>
        <div className="container" style={{ padding: 20, width: '100%' }}>
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', height: '100%', fontSize: 14 }}>
                    {message}
                </div>
            </div>
        </div>
    </div>
)

const DynamicCell = ({ reference, id, field, suffix }) => {
    const { data, isLoading, error } = useGetOne(
        reference,
        { id }
    );

    if (isLoading) return <span>Loading...</span>
    if (error) return <span>Error</span>

    if (field.indexOf('+') > -1) {
        let fields = field.split('+');
        return <span>{data[fields[0]]} {data[fields[1]]}</span>
    }
    else return <span>{data[field]}{suffix}</span>
}

const DeleteButton = ({ record, resource, confirm }) => {
    const [deleteOne, { isLoading, error }] = useDelete(
        resource,
        { id: record.id }
    );
    const handleClick = async () => {
        if (confirm) {
            if (window.confirm('Are you sure you want to delete this record?')) {
                await deleteOne();
                setTimeout(() => window.location.reload(), 1250);
            }
        } else {
            await deleteOne();
            setTimeout(() => window.location.reload(), 1250);
        }
    }
    if (error) { return <p>ERROR</p>; }

    return <Button disabled={isLoading} variant="text" startIcon={<DeleteForever />} color="primary" label="DELETE" onClick={() => handleClick()} />
};

