import React, { useEffect } from "react";
import {
    Edit,
    required, FormDataConsumer, ReferenceInput, ArrayInput, SimpleFormIterator, NumberInput,
    TextInput, DateTimeInput, SelectInput, ImageInput, ImageField, RadioButtonGroupInput,
    CheckboxGroupInput, TranslatableInputs, BooleanInput, AutocompleteArrayInput,
    usePermissions, ReferenceArrayInput, AutocompleteInput, maxLength,
    TabbedForm, FormTab, DateInput, useDataProvider, Toolbar, SaveButton, Button, useEditContext
} from 'react-admin';

// Components
import InputRow from '../../components/form/InputRow';
import SeatingPlanEditor from "../../components/seating/SeatingPlanEditor";

// Libs
import { useFormContext } from 'react-hook-form';
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import moment from 'moment';

// Utils
import { EditableImage } from "../../components/form/EditableImageField";

// UI
import { Box } from '@mui/material';
import EmptyList from "../../components/misc/EmptyList";

// Constants
const ticketGroupStatuses = [{ id: 0, name: "On Sale" }, { id: 1, name: "Sold Out" }, { id: 2, name: "Hidden" }];
const dressCodes = [{ id: 1, name: "Casual" }, { id: 2, name: "Come as you are" }, { id: 3, name: "Elegant" }, { id: 4, name: "Smart Casual" }, { id: 5, name: "No Dress Code" }];
const couplesRules = [{ id: 1, name: "No Rules" }, { id: 2, name: "Men have to be with women" }];
const eventTypes = [{ id: 1, name: 'Standard Event' }, { id: 2, name: 'Area Layout Event' }, { id: 3, name: 'Seated Event' },];
const dateTypes = [{ id: 'oneoff', name: 'Single Date / One Off' }, { id: 'session', name: 'Multiple Dates / Sessions' }];

/**
 * 
 * @returns Event V2 Edit
 */

export const EventV2Edit = (props) => {
    const { permissions } = usePermissions();
    if (!permissions) return;

    return (
        <Edit title="Edit Event" {...props} redirect={"show"}>
            <EventV2CEditForm permissions={permissions} />
        </Edit>
    );
}
const EventV2CEditForm = ({ permissions }, ...props) => {

    const dataProvider = useDataProvider();

    const { record } = useEditContext();

    const { type, dateType } = record;
    const eventType = type;

    // fetch system parameters and get "system" doc to get the default values for the fees
    const [systemParams, setSystemParams] = React.useState(null);

    React.useEffect(() => {
        dataProvider.getOne('systemParameters', { id: 'system' })
            .then(res => {
                setSystemParams(res.data);
            });

    }, []);

    if (!systemParams || !record || !permissions) {
        return <EmptyList mode="loading" />
    }

    const isAdmin = (permissions.crole && permissions.crole === "admin");
    const isOperator = (permissions.crole && permissions.crole === "operator");

    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', maxHeight: 40, marginBottom: 20, verticalAlign: 'center' }}>
                <h2 style={{ marginLeft: 15 }}>
                    {record.name} / {eventTypes.find(item => item.id == eventType) && eventTypes.find(item => item.id == eventType).name} ({dateTypes.find(item => item.id == dateType) && dateTypes.find(item => item.id == dateType).name})
                </h2>
            </div>

            <TabbedForm warnWhenUnsavedChanges id="eventForm" toolbar={<ActionToolbar />}>

                <FormTab label="Basic Details">

                    <h3>Pre Selected Types</h3>
                    <EventPreSelectionInputs eventType={eventType} dateType={dateType} />

                    <h3>Basic Details</h3>

                    <NameInput />

                    <VenuePickerInput />

                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (!formData.venue) return null;
                            return <VenueCityInput venue={formData.venue} />
                        }}
                    </FormDataConsumer>

                    <ReferenceArrayInput source="organisationCompanies" reference="organisationCompanies" label={"Organisation Company"} defaultValue={[]} sort={{ field: 'name', order: 'ASC' }}>
                        <AutocompleteArrayInput
                            fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })}
                            optionText={"name"} disabled={permissions.crole && permissions.crole === "organisationCompanyManager"}
                            value={(permissions.crole && permissions.crole === "organisationCompanyManager") && [permissions.rid]}
                            defaultValue={(permissions.crole && permissions.crole === "organisationCompanyManager") ? [permissions.rid] : []}
                        />
                    </ReferenceArrayInput>

                    <h3>Category</h3>
                    <ReferenceInput source="category" reference="categories" sort={{ field: 'name', order: 'ASC' }}>
                        <SelectInput optionText={"name.en"} fullWidth validate={required()} />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            let filter = formData.category && formData.category.length > 0 ? { category: formData.category } : { category: 'dummy' };
                            return (
                                <ReferenceArrayInput source="subcategories" reference="subcategories" label={"Sub Categories"} filter={filter} sort={{ field: 'name.en', order: 'ASC' }}>

                                    {/* <SelectArrayInput optionText="name.en" fullWidth validate={required()} label={"Sub Categories"} /> */}

                                    <AutocompleteArrayInput
                                        fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ "name.en": searchText })} validate={required()}
                                        optionText={"name.en"}
                                        label={"Sub Categories"}
                                    />


                                </ReferenceArrayInput>
                            )
                        }}
                    </FormDataConsumer>

                    {
                        (isAdmin || isOperator) && (
                            <React.Fragment>
                                <h3>Event Badge</h3>
                                <TranslatableInputs locales={['en', 'tr']} defaultLocale='en'>
                                    <TextInput source="badge.label" label="Badge Label" fullWidth defaultValue={""}
                                        validate={
                                            // max 23 characters
                                            [
                                                maxLength(23, 'The label should be at most 23 characters long.')
                                            ]
                                        }
                                    />
                                </TranslatableInputs>
                                <TextInput source="badge.textColor" label="Label Color" fullWidth defaultValue={""} />
                                <TextInput source="badge.backgroundColor" label="Background Color" fullWidth defaultValue={""} />
                            </React.Fragment>
                        )
                    }

                    <h3>Payment Methods</h3>
                    <CheckboxGroupInput source="paymentMethods"
                        defaultValue={['cc']}
                        choices={[
                            { id: 'cc', name: 'Credit Card' },
                            // { id: 'doorcash', name: 'Cash At Door' }
                        ]} validate={required()} />

                </FormTab>

                <FormTab label="Date Details">

                    <FormDataConsumer>
                        {({ formData, getSource, ...rest }) => {

                            let dateType = formData.dateType;
                            let startDate = formData.startdate;
                            let duration = formData.duration;

                            let sessions = formData.sessions;

                            if (dateType === 'oneoff') {
                                return (
                                    <React.Fragment>
                                        <DateTimeInput source="startdate" label="Event Start Date" fullWidth
                                            validate={[
                                                required(),
                                                (value) => moment(value).isBefore(moment()) ? 'Start date should be after today.' : undefined
                                            ]}
                                        />
                                        <NumberInput source="duration" label="Event Duration (minutes)" fullWidth validate={required()} />
                                        {startDate && <EndDateInput startdate={startDate} duration={duration} isEditable={isAdmin || isOperator} />}
                                    </React.Fragment>
                                )
                            } else if (dateType === 'session') {
                                return (
                                    <React.Fragment>
                                        <NumberInput source="duration" label="Event Duration (minutes)" fullWidth validate={required()} />

                                        {/* Auto Start Date and End Date Computer  */}
                                        <SessionStartAndEndDateComputer sessions={sessions} />

                                        <ArrayInput source="sessions" defaultValue={[]} label="Sessions" validate={required()}>
                                            <SimpleFormIterator
                                                disableReordering
                                                disableRemove
                                                disableAdd
                                                className="sessionEventSessionsArray"
                                                addButton={<Button variant="contained" color="primary" style={{ marginTop: 20, marginBottom: 20 }} label="Add Date" />}

                                            >
                                                <DateInput source="startdate" fullWidth label="Date"
                                                    validate={[
                                                        required(),
                                                        (value) => moment(value).isBefore(moment()) ? 'Start date should be after today.' : undefined
                                                    ]}
                                                />

                                                <ArrayInput source="times" defaultValue={[]} label="Times"
                                                >
                                                    <SimpleFormIterator
                                                        disableReordering
                                                        disableRemove
                                                        disableAdd
                                                        inline
                                                        addButton={<Button variant="contained" color="primary" style={{ marginTop: 20, marginBottom: 20 }} label="Add Time" />}
                                                    >
                                                        <FormDataConsumer>
                                                            {({ formData, scopedFormData, getSource, ...rest }) => (
                                                                <React.Fragment>
                                                                    <TextInput source={getSource("id")} label={"Session ID"} validate={required()} fullWidth disabled defaultValue={genereateSessionID()} />
                                                                    <TextInput
                                                                        source={getSource('time')} fullWidth validate={required()}
                                                                        label="Saat"
                                                                        type="time"
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                step: 300, // 5 min
                                                                            }
                                                                        }}
                                                                    />
                                                                    <BooleanInput source={getSource('isActive')} label="Active" defaultValue={true} />
                                                                </React.Fragment>

                                                            )}
                                                        </FormDataConsumer>
                                                    </SimpleFormIterator>
                                                </ArrayInput>

                                            </SimpleFormIterator>
                                        </ArrayInput>


                                    </React.Fragment>
                                )
                            }

                        }}
                    </FormDataConsumer>


                </FormTab>

                <FormTab label="More Details">

                    <h3>Event Description <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h3>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="description" label="Description" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                    </TranslatableInputs>

                    <h3>Event Rules</h3>

                    <h4>Things to know <span style={{ color: 'orange', fontStyle: 'italic', fontSize: 12 }}>(*required)</span></h4>
                    <TranslatableInputs locales={['en', 'tr']} defaultLocale='en' className="ti44">
                        <RichTextInput source="details" label="Event Rules" validate={required()} fullWidth toolbar={<RichTextInputToolbar size="small" />} />
                    </TranslatableInputs>

                    <h4>Rule Chips</h4>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Rules that are left empty won't appear on the front-end website.</span>
                    <TextInput source="rules.age" label="Age Rule" fullWidth defaultValue={null} />
                    <InputRow>
                        <SelectInput source="rules.dress" choices={dressCodes} fullWidth defaultValue={[]} />
                        <SelectInput source="rules.couples" choices={couplesRules} fullWidth defaultValue={[]} />
                    </InputRow>
                </FormTab>

                <FormTab label="Visuals">
                    <h3>Visual / Poster</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images that are of size 500 x 500 should be uploaded.</span>
                    <EditableImage record={record} source="banner" {...props} />
                    <h3>Event Video</h3>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Please note that the entry should only contain the ID (the code after ?v=) of the video NOT the whole URL.</span>
                    <TextInput source="videoURL" label={"Video ID (Youtube ID)"} fullWidth defaultValue={null} />

                    <h3>Venue Layout</h3>
                    <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                        If there is a specific layout for this event. If not default layout for the venue will be used.
                    </span>
                    <span style={{ backgroundColor: '#0ABAB5', color: 'white', borderRadius: 10, fontSize: 12.5, padding: 5, marginTop: -10, marginBottom: 8 }}>Square ratio images should be uploaded. Both sides should be equal.</span>
                    <ImageInput source="venueLayout" label=" " accept="image/*" defaultValue={null}>
                        <ImageField source="src" />
                    </ImageInput>
                </FormTab>

                {
                    (eventType == 2 || eventType == 3) && (
                        <FormTab label="Layout">
                            <h3>Layout</h3>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {

                                    if (!formData.venue) {
                                        return (
                                            <span style={{ fontSize: 14, marginTop: -10, marginBottom: 20 }}>
                                                Venue should be selected on <b>Basic Details</b> section to see the available layouts.
                                            </span>
                                        )
                                    }

                                    let layoutType = eventType == 2 ? 'area' : 'seating';
                                    let filter = { venue: formData.venue, layoutType: layoutType };

                                    if (formData.layoutID) {
                                        return (
                                            <React.Fragment>
                                                <ReferenceInput source="layoutID" label="Layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }}
                                                    validate={required()}>
                                                    <SelectInput validate={required()} optionText={"name"} fullWidth label="Layout" disabled={true} emptyText={`
                                                        Please select ${eventType == 2 ? 'an AREA' : 'a SEATING'} layout for the venue chosen. If there is no layout available, please create one using the Layouts module.
                                                    `} />
                                                </ReferenceInput>
                                                <SeatingPlanEditor seatingPlanID={formData.layoutJSONID} fullLayoutID record={formData.layoutJSONID} isAssignMode={true} ticketsID={formData.sessions[0].times[0].id} dataProvider={dataProvider} layoutType={layoutType} />
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <ReferenceInput source="layoutID" label="Layout" reference="layouts" filter={filter} sort={{ field: 'name', order: 'ASC' }}
                                                validate={required()}
                                            >
                                                <SelectInput validate={required()} optionText={"name"} fullWidth label="Layout" emptyText={`
                                                    Please select ${eventType == 2 ? 'an AREA' : 'a SEATING'} layout for the venue chosen. If there is no layout available, please create one using the Layouts module.
                                                `} />
                                            </ReferenceInput>
                                        );
                                    }

                                }}
                            </FormDataConsumer>

                        </FormTab>
                    )
                }

                <FormTab label="Coupons">
                    <h3>Coupons</h3>
                    <ArrayInput source="coupons" defaultValue={[]} label=" ">
                        <SimpleFormIterator disableReordering disableRemove className="couponsIterator">

                            {/* Disable changing of the discount amount and code if used > 0 */}
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) => {
                                    return (
                                        <React.Fragment>
                                            <TextInput source={getSource("code")} label={"Coupon Code"} validate={required()} fullWidth
                                                helperText={scopedFormData.used && scopedFormData.used > 0 ? 'If you need to change the coupon code for a USED coupon, you can do so by creating a new one' : ''}
                                                disabled={scopedFormData.used && scopedFormData.used > 0}
                                            />

                                            <NumberInput
                                                onWheel={event => { event.target.blur(); }} source={getSource("discount")} type="number" label={"Discount (TL)"} min={0}
                                                validate={required()} fullWidth disabled={scopedFormData.used && scopedFormData.used > 0}
                                                helperText={scopedFormData.used && scopedFormData.used > 0 ? 'If you need to change the discount amount for a USED coupon, you can do so by creating a new one' : ''}
                                            />

                                            <NumberInput onWheel={event => { event.target.blur(); }} source={getSource("stock")} type="number" label={"Stock"} validate={required()} fullWidth />
                                            {/* <NumberInput onWheel={event => { event.target.blur(); }} source="used" type="number" label={"Used"} fullWidth disabled /> */}

                                            <DateInput source={getSource("activeUntil")} label={"Active Until"} validate={[
                                                required(),
                                                (value) => moment(value).isBefore(moment()) ? 'Active until date should be after the start date.' : undefined,
                                                (value) => moment(value).isAfter(moment(formData.startdate)) ? 'Active until date should be before the start date.' : undefined
                                            ]} fullWidth />

                                        </React.Fragment>
                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                <FormTab label="Fees">
                    {
                        systemParams && systemParams.variables ? (
                            <React.Fragment>
                                <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)"
                                    helperText="Service fee to cover extra operational cost"
                                    defaultValue={systemParams.variables.defaultServiceFee}
                                    disabled={(permissions.crole && permissions.crole !== "admin")}
                                />

                                <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee"
                                    min={0} fullWidth label="Event Commission Fee (%)"
                                    defaultValue={systemParams.variables.defaultCommissionFee}
                                    disabled={(permissions.crole && permissions.crole !== "admin")}
                                />

                                <BooleanInput source="isCommissionExtra" defaultValue={false} label={`Is commission extra (added to ticket prices)?`} helperText="Please note that the prices for the event will change if this is turned on." />
                            </React.Fragment>
                        ) : (
                            <div style={{ padding: 20, color: 'red' }}>
                                <h3>System Parameters could not be loaded, this is an error that needs to be reported to Gişe Kıbrıs. Please do so before editing your event.</h3>
                            </div>
                            // <React.Fragment>
                            //     <NumberInput onWheel={event => { event.target.blur(); }} source="servicefee" min={0} fullWidth label="Event Service Fee (TL)" helperText="Service fee to cover extra operational cost" defaultValue={0} />
                            //     <NumberInput onWheel={event => { event.target.blur(); }} source="commissionFee" min={0} fullWidth label="Event Commission Fee (%)" defaultValue={10} />
                            //     <BooleanInput source="isCommissionExtra" defaultValue={false} label="Is commission extra (added to ticket prices)?" helperText="Please note that the prices for the event will change if this is turned on." />
                            // </React.Fragment>
                        )
                    }
                </FormTab>

                {/* FormTab - Contract */}

                <FormTab label="Settings">
                    <SettingsInputs permissions={permissions} record={record} />
                </FormTab>

            </TabbedForm>
        </React.Fragment>
    )
}

// FORM COMPONENTS
const ActionToolbar = () => {
    const dataProvider = useDataProvider();

    return (
        <Toolbar>
            <SaveButton
                label="Save"
                alwaysEnable
                transform={async (data) => {

                    // let toReturn = { ...data };

                    // handle seatplans
                    // update eventLayouts/data.id with layoutJSON: seatingPlan
                    if (data && data.id && data.layoutID && data.layoutJSON) {
                        await dataProvider.update('eventLayouts', {
                            id: data.id,
                            data: {
                                layoutJSON: data.layoutJSON
                            }
                        });

                        // remove layoutJSON from the data
                        delete data.layoutJSON;
                    }

                    return { ...data, notify: true };

                }}
                type="button"
            />
        </Toolbar>
    )
}
const EndDateInput = ({ startdate, duration, isEditable }) => {
    
    const { setValue } = useFormContext();

    useEffect(() => {
        const startDate = new Date(startdate);
        const endDate = new Date(startDate.getTime() + duration * 60000);
        setValue('enddate', endDate);
    }, [startdate, duration]);


    return (
        <React.Fragment>
            <DateTimeInput source="enddate" label="Event End Date" fullWidth validate={required()} disabled={isEditable ? false : true} />
            {
                isEditable && (
                    <span>
                        This is only editable by admin or operator
                    </span>
                )
            }
        </React.Fragment>
    )

}
const EventPreSelectionInputs = ({ eventType, dateType }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        setValue('type', eventType);
        setValue('dateType', dateType);

    }, [eventType, dateType]);

    return (
        <React.Fragment>
            <SelectInput source="type" label="Event Type" fullWidth disabled choices={eventTypes} />
            <SelectInput source="dateType" label="Event Date Type" fullWidth disabled choices={dateTypes} />
        </React.Fragment>
    )

}

const VenueCityInput = ({ venue }) => {
    const { setValue } = useFormContext();
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getOne('venues', { id: venue })
            .then(res => {
                setValue('city', res.data.city);
            });

    }, [venue]);

    return (
        <TextInput source="city" label="City" fullWidth disabled />
    )

}
const VenuePickerInput = ({ }) => {
    const { setValue } = useFormContext();
    return (
        <ReferenceInput source="venue" reference="venues" sort={{ field: 'name', order: 'ASC' }} validate={required()}>
            <AutocompleteInput optionText="name" fullWidth matchSuggestion={() => true} filterToQuery={searchText => ({ name: searchText })} validate={required()}
                onChange={() => {
                    setValue('layoutID', null);
                }}
            />
        </ReferenceInput>
    )
}
const SettingsInputs = ({ permissions, record }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        setValue('isActive', (permissions.crole && permissions.crole === "admin") ? true : false);
        setValue('isVerified', (permissions.crole && permissions.crole === "admin") ? true : false);
    }, []);

    return (
        <React.Fragment>
            <BooleanInput source="isActive" label="Show this event on the website?" helperText="Please note that the event should be 'saved' for this change to take effect." disabled={(permissions.crole && permissions.crole !== "admin")} />
            <BooleanInput source="isVerified" label="Verified Event?" helperText="Is event verified by the admin?" disabled={(permissions.crole && permissions.crole !== "admin")} />
        </React.Fragment>

    )
}
const NameInput = ({ }) => {
    const { setValue } = useFormContext();

    // as the user types, the name should be auto capitalized
    const handleNameChange = (e) => {
        setValue('name', e.target.value.toUpperCase());
    }

    return (
        <TextInput source="name" label="Event Name" fullWidth validate={required()} onBlur={handleNameChange} />
    )
}
const SessionStartAndEndDateComputer = ({ sessions }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        if (sessions && sessions.length > 0) {
            let startdate = moment(sessions[0].startdate).set({ hour: 0, minute: 0, second: 0 }).toDate();
            let enddate = moment(sessions[sessions.length - 1].startdate).set({ hour: 23, minute: 59, second: 59 }).toDate();
            setValue('startdate', startdate);
            setValue('enddate', enddate);
        }
    }, [sessions]);

    return null;
}

// UTILS
const genereateTicketID = () => {
    // Capital letters and numbers, length 8
    return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substring(0, 8).toUpperCase();
}
const genereateSessionID = () => {
    // Capital letters and numbers, length 12
    return Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substring(0, 12).toUpperCase();
}